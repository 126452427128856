import {
  STORE_CSV,
  CLEAR_CSV,
} from '../actions/csv'

const initialState = {
  fields: [],
  data: [],
}

export default function csvReducer(state = initialState, action) {
  switch (action.type) {
  case CLEAR_CSV:
    return initialState
  case STORE_CSV:
    const { fields, data } = action.payload

    return { fields, data: state.data.concat(data) }
  default:
    return state
  }
}
