export const CREATE_MESSAGE          = '[message] Create'
export const UPDATE_MESSAGE          = '[message] Update'
export const  FETCH_MESSAGES         = '[messages] Fetch'
export const  SEND_MESSAGE           = '[message] Send'
export const DELETE_MESSAGE          = '[message] Delete'

export const  STORE_MESSAGES         = '[messages] Update store'
export const  STORE_MESSAGE          = '[message] Update store'
export const  STORE_REMOVE_MESSAGE   = '[message] Remove from store'
export const  UNDO_REMOVE_MESSAGE    = '[message] Undo Remove from store'

export const DOWNLOAD_XML         = '[message] Download XML'

const url = '/rest/testtool/messages'

export const fetchMessages = () => ({
  type: FETCH_MESSAGES,
  payload: {
    method: 'get',
    url,
  }
})

export const storeMessages = items => ({
  type: STORE_MESSAGES,
  payload: { items },
})

export const createMessage = message => ({
  type: CREATE_MESSAGE,
  payload: {
    url,
    message,
  }
})

export const updateMessage = message => ({
  type: UPDATE_MESSAGE,
  payload: {
    url: `${url}/${message._id}`,
    message,
  }
})

export const deleteMessage = id => ({
  type: DELETE_MESSAGE,
  payload: {
    url: `${url}/${id}`,
    id,
  }
})


export const storeMessage = message => ({
  type: STORE_MESSAGE,
  payload: { message }
})

export const storeRemoveMessage = id => ({
  type: STORE_REMOVE_MESSAGE,
  payload: { id }
})

export const undoStoreRemoveMessage = () => ({
  type: UNDO_REMOVE_MESSAGE,
  payload: { }
})


export const sendMessage = (message, opdrachtId, withAttachments, xml) => ({
  type: SEND_MESSAGE,
  payload: {
    url: `/rest/testtool/send/${message._id}`,
    opdrachtId,
    withAttachments,
    xml,
  }
})

export const downloadXml = messageId => ({
  type: DOWNLOAD_XML,
  payload: {
    url: `${url}/${messageId}`,
    messageId,
  }
})

