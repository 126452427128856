export const UPDATE_PROGRESS = '[progressbar] Update'
export const CLEAR_PROGRESS = '[progressbar] Clear'

export const updateProgressbar = (now, max, text) => ({
  type: UPDATE_PROGRESS,
  payload: { now, max, text }
})

export const clearProgressbar = () => ({
  type: CLEAR_PROGRESS,
  payload:{}
})
