import { SELECT_PARTY, SELECT_VERSION } from '../actions/page'
import { fetchEndpoints } from '../actions/endpoints'
import { fetchCertificates } from '../actions/certificates'
import { getSelectedVersion, getSelectedParty } from '../reducers/page'

export const selectPartyFlow = ({ dispatch, getState }) => next => action => {
  next(action)

  if (action.type === SELECT_PARTY) {
    const { party } = action.payload
    const version = getSelectedVersion(getState())

    if (party && version) {
      dispatch(fetchEndpoints({ party, version }))
      dispatch(fetchCertificates({ party, version }))
    }
  }
}

export const selectVersionFlow = ({ dispatch, getState }) => next => action => {
  next(action)

  if (action.type === SELECT_VERSION) {
    const party = getSelectedParty(getState())
    const { version } = action.payload

    if (party && version) {
      dispatch(fetchEndpoints({ party, version }))
      dispatch(fetchCertificates({ party, version }))
    }
  }
}

export const pageMiddleware = [selectPartyFlow, selectVersionFlow]
