import './tablescroll.css'

import {
  Panel,
  Row, Col,
} from 'react-bootstrap'
import React from 'react'
import moment from 'moment'

import PropTypes from 'prop-types'

const OPLEVERING = 500

export function OpdrachtHistoryList(props) {

  return (
    <Panel bsStyle="primary" defaultExpanded>
      <Panel.Heading>
        <Panel.Title toggle>
          <strong>{props.title}</strong>
        </Panel.Title>
      </Panel.Heading>
      <Panel.Collapse>
        <div className="scroll-header">
          <Row>
            <Col md={4}>Status beschrijving</Col>
            <Col md={5}>Status Oplevering</Col>
            <Col md={2}>Gewijzigd</Col>
          </Row>
        </div>
        <div className="scroll-container">

          { props.list.sort((a,b)=> moment.utc(b.changed) - moment.utc(a.changed)).map(os => {
            const wijzigingsDatum = os.changed ? moment(os.changed).format('YYYY-MM-DD HH:mm') : ''

            return (
              <Row key={`${os.status} ${os.tgStatus} ${os.agaStatus} ${os.agpStatus} ${os.changed}`} className={os === props.selected ? 'selected' : ''} >
                <Col md={4}>{os.beschrijving}</Col>
                <Col md={5}>
                  { os.status === OPLEVERING &&
                    <React.Fragment>
                      <Row>
                        <Col md={12}>{os.statusTextTG}</Col>
                      </Row><Row>
                        <Col md={12}>{os.statusTextAGA}</Col>
                      </Row><Row>
                        <Col md={12}>{os.statusTextAGP}</Col>
                      </Row>
                    </React.Fragment>
                  }
                </Col>
                <Col md={2}>{wijzigingsDatum}</Col>
              </Row>
            )}
          )}

        </div>
      </Panel.Collapse>
      <Panel.Footer>
        {`${props.list.length} ${props.list.length === 1 ? 'resultaat' : 'resultaten'} gevonden.`}
      </Panel.Footer>
    </Panel>
  )}


const OpdrachtStatusType = PropTypes.shape({
  opdrachtId   : PropTypes.string.isRequired,
  statusText   : PropTypes.string.isRequired,
  beschrijving : PropTypes.string.isRequired,
})

OpdrachtHistoryList.propTypes = {
  list                 : PropTypes.arrayOf(OpdrachtStatusType),
  selected             : OpdrachtStatusType,
  title                : PropTypes.string,
}

OpdrachtHistoryList.defaultProps = {
  selectedId : '',
  title              : 'Zoek Resultaten',
  list: [],
}
export default OpdrachtHistoryList
