import React, { Fragment } from 'react'

export const ADD_ALERT    = '[alert] Add'
export const REMOVE_ALERT = '[alert] Remove'
export const CLEAR_ALERTS = '[alert] Clear'

export const addAlert = (options) => ({
  type: ADD_ALERT,
  payload: {
    ...options
  }
})

export const info = (message, title) => addAlert({
  type: 'info',
  timeout: 3000,
  headline: title,
  message,
})

export const warning = (message, title) => addAlert({
  type: 'warn',
  timeout: 5000,
  headline: title,
  message,
})

export const error = (message, title) => addAlert({
  type: 'danger',
  timeout: 60000,
  headline: title,
  message,
})

export const success = (message, title) => addAlert({
  type: 'success',
  timeout: 3000,
  headline: title,
  message,
})

export const removeAlert = ({ id }) => ({
  type: REMOVE_ALERT,
  payload: {
    id
  }
})

export const clearAlerts = () => ({
  type: CLEAR_ALERTS,
  payload: {}
})

const apiAlert = ({ type, timeout }) => (title, { message, messageDetail }) =>  ({
  type: ADD_ALERT,
  payload: {
    type,
    timeout,
    headline: title,
    message:
    <Fragment>
      <p>
        <em>{message}</em><br/>
        <small>{Array.isArray(messageDetail) ? <ul>{messageDetail.map(m => <li key={m}>{m}</li>)}</ul> : messageDetail}</small>
      </p>
    </Fragment>
  }
})

export const apiError = apiAlert({ type: 'danger' })
export const apiInfo = apiAlert({ type: 'info', timeout: 3000 })
