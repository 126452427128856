import {
  Button,
  Grid,
  Row, Col,
} from 'react-bootstrap'
import React, { Component } from 'react'

import PropTypes from 'prop-types'

import NieuweOpdrachtView from './NieuweOpdrachtView'
import OpdrachtHistoryList from './OpdrachtHistoryList'
import OpdrachtStatusDetail from './OpdrachtStatusDetail'
import OpdrachtStatusList from './OpdrachtStatusList'
import SearchForm from './SearchForm'
import VervolgBerichten from './VervolgBerichten'


export class OpdrachtStatus extends Component {
  state = {}

  componentDidMount() {
    const { query } = this.props

    if (query && query.length > 0)
      this.query()

    this.setState({ nieuweOpdracht:false, searchVisible: true })
  }

  query = () => {
    this.props.queryOpdrachtStatus(this.props.query)
    this.setState({ nieuweOpdracht:false, searchVisible: true })
  }

  selectOpdrachtStatus = id => {
    this.props.selectOpdrachtStatus(id)
  }

  onNieuweOpdracht = () => {
    this.props.selectMessage()

    this.setState({ nieuweOpdracht:true, submitted: false, searchVisible: false })
  }

  render() {
    const props = this.props

    return (
      <Grid>
        <Row><Col sm={
          props.showNieuweOpdracht ? 10 : 12 // eslint-disable-line
        }>
          <SearchForm key={props.query} query={props.query}  onSubmit={props.onSearch}/>
        </Col>
        {props.showNieuweOpdracht &&
            <Col sm={2}>
              {
                this.state.nieuweOpdracht
                  ?  <Button bsStyle="info" onClick={() => this.setState({ nieuweOpdracht: false, searchVisible: true })}>Zoek resultaten</Button>
                  : <Button bsStyle="primary" onClick={this.onNieuweOpdracht}>Nieuwe Opdracht</Button>
              }
            </Col>
        }
        </Row>

        { this.state.searchVisible && (this.props.opdrachtstatuses.length > 0
          ?
          <React.Fragment>
            { props.showTest && <OpdrachtStatusList list={props.opdrachtstatuses} selected={props.selectedOpdrachtStatus}
              selectOpdrachtStatus={this.selectOpdrachtStatus} title="Opdracht Status Overzicht" />
            }
            {  props.selectedOpdrachtStatus &&
                  <React.Fragment>
                    <OpdrachtStatusDetail key={props.selectedOpdrachtStatus._id} {...props.selectedOpdrachtStatus} />
                    <OpdrachtHistoryList list={props.selectedOpdrachtStatus.history} title="Historie" />
                  </React.Fragment>

            }
            {props.showTest && <VervolgBerichten />}
          </React.Fragment>
          :
          <div>Geen opdrachten beschikbaar voor huidige zoekcriteria</div>
        )}

        { this.state.nieuweOpdracht &&
             <NieuweOpdrachtView />
        }

      </Grid>
    )
  }
}

OpdrachtStatus.propTypes = {
  opdrachtstatuses: PropTypes.array.isRequired,
  selectMessage: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  queryOpdrachtStatus: PropTypes.func.isRequired,
  selectedOpdrachtStatus: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }),
  showTest: PropTypes.bool,
  showNieuweOpdracht: PropTypes.bool,
}

OpdrachtStatus.defaultProps = {
  showTest: false,
  showNieuweOpdracht: false,
}

export default OpdrachtStatus
