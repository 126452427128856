import React, { Component } from 'react'
import TemplateMaintenance from '../templates/TemplateMaintenance'
import MessageMaintenance from '../messages/MessageMaintenance'

export class MaintenanceView extends Component {

  render() {
    const props = this.props

    return (<div>
      <TemplateMaintenance {...props}/>
      <MessageMaintenance {...props}/>
    </div>)
  }
}
export default MaintenanceView
