export const  SAVE_ENDPOINT   = '[endpoint] Save'
export const FETCH_ENDPOINTS  = '[endpoints] Fetch'

export const  STORE_ENDPOINTS = '[endpoints] Update store'
export const  STORE_ENDPOINT  = '[endpoint] Update store'

export const  FETCH_PARTIES   = '[party] Fetch'
export const  STORE_PARTIES   = '[party] Update store'

const url = '/rest/connectivity/endpoints'
const partyUrl = '/rest/connectivity/parties'

export const fetchEndpoints = ({ party, version }) => ({
  type: FETCH_ENDPOINTS,
  payload: {
    url: `${url}/${party}/${version}`,
  }
})

export const storeEndpoints = items => ({
  type: STORE_ENDPOINTS,
  payload: { items },
})

export const saveEndpoint = ({ party, version, system, endpoint }) => ({
  type: SAVE_ENDPOINT,
  payload: {
    url: `${url}/${party}/${version}/${system}`,
    endpoint,
    party, system,
  }
})

export const storeEndpoint = endpoint => ({
  type: STORE_ENDPOINT,
  payload: { endpoint }
})

export const fetchParties = () => ({
  type: FETCH_PARTIES,
  payload: {
    url: partyUrl
  }
})

export const storeParties = items => ({
  type: STORE_PARTIES,
  payload: { items }
})
