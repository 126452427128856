import { unregister } from './registerServiceWorker'
import 'bootstrap/dist/css/bootstrap.min.css'

import './index.css'

import { Provider } from 'react-redux'
import { faDownload, faEraser, faEdit, faCopy, faInfo } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import React from 'react'
import ReactDOM from 'react-dom'

import MainRoutes from './routes'
import store from './redux/store'

library.add(faDownload, faEraser, faEdit, faCopy, faInfo)

ReactDOM.render(
  <Provider store={store}>
    <MainRoutes />
  </Provider>,
  document.getElementById('root'))

unregister()

if (module.hot) {
  module.hot.accept('./routes', () => {
    const NextRoutes = require('./routes').default

    ReactDOM.render(
      <Provider store={store}>
        <NextRoutes />
      </Provider>,
      document.getElementById('root')
    )
  })
}
