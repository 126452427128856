import {
  STORE_ENDPOINT,
  STORE_ENDPOINTS,
  STORE_PARTIES,
} from '../actions/endpoints'
const initialState = {
  items: {},
  parties: []
}

export default function endpointReducer(state = initialState, action) {
  switch (action.type) {
  case STORE_ENDPOINTS:
    const items = action.payload.items.reduce(
      (result, item) => ({ ...result, [item.system]: item }),
      {}  // empty object as start value
    )

    return { ...state, items }
  case STORE_ENDPOINT:
    const { endpoint } = action.payload

    return { ...state, items: { ...state.items, [endpoint.system]: endpoint } }

  case STORE_PARTIES:
    const parties = action.payload.items

    return { ...state, parties }

  default:
    return state
  }
}

export const getEndpoints = state => Object.values(state.endpoints.items)
export const getEndpointsBySystem = (state, system) => ({ ...state.endpoints.items[system] })

export const getParties = state => state.endpoints.parties


