export const FETCH_PROFILE          = '[auth] Fetch profile'
export const STORE_PROFILE          = '[auth] Store profile'
export const AUTH_LOGIN             = '[auth] Login'
export const AUTH_LOGIN_HANDLE      = '[auth] Login handle'
export const AUTH_LOGIN_SUCCESS     = '[auth] Login success'
export const AUTH_LOGIN_FAILURE     = '[auth] Login failure'
export const AUTH_LOGOUT            = '[auth] Logout'
export const AUTH_ADD_SESSION_TIMER = '[auth] Timer add'

export const login = () => ({
  type: AUTH_LOGIN,
  payload: {}
})

export const handleAuthentication = () => ({
  type: AUTH_LOGIN_HANDLE,
  payload: {}
})

export const loginSuccess = authResult => ({
  type: AUTH_LOGIN_SUCCESS,
  payload: { authResult }
})

export const loginFailure = () => ({
  type: AUTH_LOGIN_FAILURE,
  payload: {}
})

export const logout = () => ({
  type: AUTH_LOGOUT,
  payload: {}
})

export const fetchProfile = () => ({
  type: FETCH_PROFILE,
  payload: {}
})

export const storeProfile = profile => ({
  type: STORE_PROFILE,
  payload: { profile }
})

export const addSessionTimer = () => ({
  type: AUTH_ADD_SESSION_TIMER,
  payload: {}
})
