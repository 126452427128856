import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from 'react-bootstrap'
import { connect } from 'react-redux'

import { TemplateForm, TemplateTable, TemplateRow } from '.'
import { fetchMessages } from '../redux/actions/messages'
import {
  fetchTemplates,
  createTemplate,
  updateTemplate
} from '../redux/actions/templates'
import {
  selectTemplate,
  selectVersion,
} from '../redux/actions/page'
import {
  getTemplateById,
  getSelectedTemplate,
  getSelectedVersion,
  getSortedTemplatesBySelectedVersion,
  getVersions,
} from '../redux/selectors'

export class TemplateMaintenance extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      showModal: false,
    }
  }

  componentDidMount() {
    this.props.fetchTemplates()
    this.props.fetchMessages()
  }

  onCreate = () => {
    this.setState({ currentTemplate: { _id: undefined, sender:'', status:'',version:'', description:'', template:'' }, showModal: true })
  }

  onEdit = id => {
    const currentTemplate = { ...this.props.getTemplateById(id) }

    this.setState({ currentTemplate, showModal: true })
  }

  onCopy = id => {
    const currentTemplate = { ...this.props.getTemplateById(id) }

    currentTemplate._id = null
    currentTemplate.description += ' - kopie'

    this.setState({ currentTemplate, showModal: true })
  }

  onSubmit = (template) => {
    this.setState({ showModal: false })

    if (template._id) {
      this.props.updateTemplate(template)
    } else {
      this.props.createTemplate(template)
    }
  }



  render() {
    return (
      <Grid>
        <TemplateTable {...this.props}
          onCreate={this.onCreate}
          onSelectVersion={this.props.selectVersion}>
          {this.props.templates.map(template => {
            const { selectedTemplate } = this.props
            const selected = selectedTemplate && selectedTemplate._id === template._id

            return (
              <TemplateRow key={template._id} template={template}
                selected={selected}
                onEdit={this.onEdit}
                onCopy={this.onCopy}
                onSelect={this.props.selectTemplate}
              />

            )})}
        </TemplateTable>
        <TemplateForm show={this.state.showModal} template={this.state.currentTemplate}
          onSubmit={this.onSubmit} onClose={() => this.setState({ showModal: false })}/>
      </Grid>
    )
  }
}

TemplateMaintenance.propTypes = {
  selectVersion: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  getTemplateById: PropTypes.func.isRequired,
  updateTemplate: PropTypes.func.isRequired,
  createTemplate: PropTypes.func.isRequired,
  templates: PropTypes.array.isRequired,
  selectedTemplate: PropTypes.shape({
    _id: PropTypes.string.isRequired,

  })

}

TemplateMaintenance.defaultProps = {
  templates: [],
}

const mapStateToProps = (state) =>  ({
  selectedTemplate : getSelectedTemplate(state),
  templates        : getSortedTemplatesBySelectedVersion(state),
  version          : getSelectedVersion(state),
  versions         : getVersions(state),
  getTemplateById  : id => getTemplateById(state, id)
})

export default connect(mapStateToProps, {
  selectTemplate, selectVersion, fetchTemplates, fetchMessages,
  createTemplate, updateTemplate
})(TemplateMaintenance)
