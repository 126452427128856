import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  ControlLabel,
  FormControl,
  FormGroup,
  HelpBlock,
  MenuItem,
  DropdownButton,
  Modal,
} from 'react-bootstrap'

const senderOptions = ['Netbeheerder', 'Aannemer']

class TemplateForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      description:'',
      endpoint: '',
      sender: '',
      status: '',
      version: '',
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.template !== prevProps.template) {
      this.setState({ ...this.props.template, submitted: false })
    }
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value.trimStart() })

  handleSelect = prop => value => this.setState({ [prop]: value })

   validateProp = prop => {
     const length = this.state[prop].length

     if (length === 0) {
       return this.state.submitted ? 'error' : null
     }

     return 'success'
   }

  onSubmit = e => {
    const isValid =
      [ 'description', 'endpoint', 'sender', 'status', 'version' ]
        .map(this.validateProp)
        .reduce((acc, cur) => (acc && cur === 'success'), true)

    if (!isValid) {
      return this.setState({ submitted: true })
    }

    const { _id, description, endpoint, sender, status, version } = this.state

    this.props.onSubmit({ _id, description, endpoint, sender, status, version })
  }

  render() {
    const {
      _id,
      description,
      endpoint,
      sender,
      status,
      version,
    } = this.state

    return (

      this.props.show
        ?  <div className="static-modal">
          <Modal.Dialog>
            <Modal.Header>
              <Modal.Title>{_id ? 'Berichttemplate bewerken' : 'Berichttemplate aanmaken'}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <form>
                <FormGroup controlId="description" validationState={this.validateProp('description')} >
                  <ControlLabel>Omschrijving</ControlLabel>
                  <FormControl type="text" name="description" value={description} placeholder="Bericht omschrijving" onChange={this.handleChange} />
                  <FormControl.Feedback />
                  <HelpBlock>Omschrijving van het soort bericht</HelpBlock>
                </FormGroup>

                <FormGroup controlId="version" validationState={this.validateProp('version')}>
                  <ControlLabel>Bericht versie</ControlLabel><br/>
                  <FormControl type="text" name="version" value={version} placeholder="1.35" onChange={this.handleChange} />
                  <FormControl.Feedback />
                  <HelpBlock>De versie van het bericht</HelpBlock>
                </FormGroup>

                <FormGroup controlId="sender" validationState={this.validateProp('sender')}>
                  <ControlLabel>Te sturen als</ControlLabel>
                  <br/>
                  <DropdownButton id="sender"
                    title={sender || 'Selecteer verzender'}
                    onSelect={this.handleSelect('sender')} value={sender} bsStyle={this.validateProp('sender') || 'default'} >
                    { senderOptions.map(s =>
                      <MenuItem key={s} eventKey={s} active={sender === s}>{s}</MenuItem>
                    )}
                  </DropdownButton>
                  <FormControl.Feedback />
                  <HelpBlock>Welke partij stuurt dit bericht normaal gesproken?</HelpBlock>
                </FormGroup>

                <FormGroup controlId="status" validationState={this.validateProp('status')} >
                  <ControlLabel>Te sturen vanuit status</ControlLabel>
                  <FormControl type="text" name="status" value={status} placeholder="500,520" onChange={this.handleChange} />
                  <FormControl.Feedback />
                  <HelpBlock>Komma gescheiden lijst met alle mogelijke statussen van waaruit dit bericht gestuurd mag worden</HelpBlock>
                </FormGroup>

                <FormGroup controlId="endpoint" validationState={this.validateProp('endpoint')} >
                  <ControlLabel>Endpoint</ControlLabel>
                  <FormControl type="text" name="endpoint" value={endpoint} placeholder="https://" onChange={this.handleChange} />
                  <FormControl.Feedback />
                  <HelpBlock>Relative endpoint url (zonder host) voor dit bericht in DSP</HelpBlock>
                </FormGroup>
              </form>
            </Modal.Body>

            <Modal.Footer>
              <Button onClick={this.props.onClose}>Sluiten</Button>
              <Button bsStyle="success" className="pullRight" onClick={this.onSubmit}>Opslaan</Button>
            </Modal.Footer>
          </Modal.Dialog>
        </div>
        : false
    )}
}

TemplateForm.propTypes = {
  show: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  template: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  })
}

TemplateForm.defaultProps = {
  show: false,
}

export default TemplateForm
