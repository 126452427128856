import {
  CREATE_RULE,
  FETCH_RULES,
  UPDATE_RULE,
  DELETE_RULE,
  ACTIVATE_RULES,
  VALIDATE_RULES,
  RESET_RULES,
  EXPORT_RULES,
  IMPORT_RULES,
  FETCH_OPDRACHTNEMERS,
  storeRule, storeRules,
  storeRemoveRule,
  undoStoreRemoveRule,
  fetchDraftRules,
  clearRules,
  fetchOpdrachtnemers,
  storeOpdrachtnemers
} from '../actions/rules'
import {
  API_SUCCESS, API_ERROR,
} from '../actions/api'
import {
  apiError,
  clearAlerts,
  success
} from '../actions/alerts'
import { apiRequest } from '../actions/api'
import { selectOpdrachtnemer, storeFileForDownload } from '../actions/page'
import { getSelectedOpdrachtnemer } from '../reducers/page'
import qs from 'qs'

export const fetchRulesFlow = ({ getState, dispatch }) => next => action => {
  next(action)

  if (action.type === FETCH_RULES) {
    const { url, active } = action.payload
    const opdrachtnemer = getSelectedOpdrachtnemer(getState())
    const query = `${url}?${qs.stringify({ active, opdrachtnemer })}`

    dispatch(clearRules())
    dispatch(apiRequest({ method:'GET', url:query, feature:FETCH_RULES }))
  }

  if (action.type === `${FETCH_RULES} ${API_SUCCESS}`) {
    dispatch(storeRules(action.payload))
  }

  if (action.type === `${FETCH_RULES} ${API_ERROR}`) {
    dispatch(clearAlerts())
    dispatch(apiError('Fout bij ophalen samenwerkingsverband', action.payload))
  }
}

export const createRuleFlow = ({ getState, dispatch }) => next => action => {
  next(action)

  if (action.type === CREATE_RULE) {
    dispatch(apiRequest({ method:'POST', url:action.payload.url, body: action.payload.rule, feature: CREATE_RULE }))
  }

  if (action.type === `${CREATE_RULE} ${API_SUCCESS}`) {
    if(action.payload.opdrachtnemer !== getSelectedOpdrachtnemer(getState())) {
      dispatch(selectOpdrachtnemer(action.payload.opdrachtnemer))
      dispatch(fetchDraftRules())
      dispatch(fetchOpdrachtnemers())
    }

    dispatch(clearAlerts())
    dispatch(success('Wijzigingen opgeslagen'))
    dispatch(storeRule(action.payload))
  }

  if (action.type === `${CREATE_RULE} ${API_ERROR}`) {
    dispatch(clearAlerts())
    dispatch(apiError('Fout bij opslaan van wijzigingen', action.payload))
  }
}

export const updateRuleFlow = ({ dispatch }) => next => action => {
  next(action)

  if (action.type === UPDATE_RULE) {
    dispatch(apiRequest({ method:'PUT', url:action.payload.url, body:action.payload.rule, feature: UPDATE_RULE }))
  }

  if (action.type === `${UPDATE_RULE} ${API_SUCCESS}`) {
    dispatch(clearAlerts())
    dispatch(success('Wijzigingen opgeslagen'))
    dispatch(storeRule(action.payload))
  }

  if (action.type === `${UPDATE_RULE} ${API_ERROR}`) {
    dispatch(clearAlerts())
    dispatch(apiError('Fout bij opslaan van wijzigingen', action.payload))
  }
}

export const deleteRuleFlow = ({ dispatch }) => next => action => {
  next(action)

  if (action.type === DELETE_RULE) {
    dispatch(storeRemoveRule(action.payload.id))
    dispatch(apiRequest({ method:'DELETE', url:action.payload.url, feature: DELETE_RULE }))
  }

  if (action.type === `${DELETE_RULE} ${API_SUCCESS}`) {
    dispatch(clearAlerts())
    dispatch(success('Verwijderd'))
  }

  if (action.type === `${DELETE_RULE} ${API_ERROR}`) {
    dispatch(clearAlerts())
    dispatch(undoStoreRemoveRule())
    dispatch(apiError('Fout bij verwijderen', action.payload))
  }
}

export const activateRulesFlow = ({ getState, dispatch }) => next => action => {
  next(action)

  if (action.type === ACTIVATE_RULES) {
    const body = { opdrachtnemer: getSelectedOpdrachtnemer(getState()) }

    dispatch(apiRequest({ method:'POST', url:action.payload.url, body, feature: ACTIVATE_RULES }))
  }

  if (action.type === `${ACTIVATE_RULES} ${API_SUCCESS}`) {
    dispatch(clearAlerts())
    dispatch(success('Activatie succesvol'))
  }

  if (action.type === `${ACTIVATE_RULES} ${API_ERROR}`) {
    dispatch(clearAlerts())
    dispatch(apiError('Fout bij activeren', action.payload))
  }
}

export const validateRulesFlow = ({ getState, dispatch }) => next => action => {
  next(action)

  if (action.type === VALIDATE_RULES) {
    const body = { opdrachtnemer: getSelectedOpdrachtnemer(getState()) }

    dispatch(apiRequest({ method:'POST', url:action.payload.url, body, feature: VALIDATE_RULES }))
  }

  if (action.type === `${VALIDATE_RULES} ${API_SUCCESS}`) {
    dispatch(clearAlerts())
    dispatch(success('Validatie succesvol'))
  }

  if (action.type === `${VALIDATE_RULES} ${API_ERROR}`) {
    dispatch(clearAlerts())
    dispatch(apiError('Fout bij valideren', action.payload))
  }
}

export const resetRulesFlow = ({ getState, dispatch }) => next => action => {
  next(action)

  if (action.type === RESET_RULES) {
    const body = { opdrachtnemer: getSelectedOpdrachtnemer(getState()) }

    dispatch(apiRequest({ method:'POST', url:action.payload.url, body, feature: RESET_RULES }))
  }

  if (action.type === `${RESET_RULES} ${API_SUCCESS}`) {
    dispatch(fetchDraftRules())
    dispatch(clearAlerts())
    dispatch(success('Reset succesvol'))
  }

  if (action.type === `${RESET_RULES} ${API_ERROR}`) {
    dispatch(clearAlerts())
    dispatch(apiError('Fout bij reset', action.payload))
  }
}

export const exportRulesFlow = ({ getState, dispatch }) => next => action => {
  next(action)

  if (action.type === EXPORT_RULES) {
    const { url, active } = action.payload
    const opdrachtnemer = getSelectedOpdrachtnemer(getState())
    const query = `${url}?${qs.stringify({ active, opdrachtnemer })}`

    dispatch(apiRequest({ method:'GET', url:query, feature: EXPORT_RULES }))
  }

  if (action.type === `${EXPORT_RULES} ${API_SUCCESS}`) {
    const opdrachtnemer = getSelectedOpdrachtnemer(getState())
    const filename = `Export samenwerkingsverband ${opdrachtnemer}.csv`

    dispatch(storeFileForDownload(filename, action.payload))
    dispatch(clearAlerts())
    dispatch(success('Export succesvol'))
  }

  if (action.type === `${EXPORT_RULES} ${API_ERROR}`) {
    dispatch(clearAlerts())
    dispatch(apiError('Fout bij export', action.payload))
  }
}

export const importRulesFlow = ({ dispatch }) => next => action => {
  next(action)

  if (action.type === IMPORT_RULES) {
    dispatch(apiRequest({ method:'POST', url:action.payload.url, body: action.payload.rules, feature: IMPORT_RULES }))
  }

  if (action.type === `${IMPORT_RULES} ${API_SUCCESS}`) {
    dispatch(fetchDraftRules())
    dispatch(clearAlerts())
    dispatch(success('Import succesvol'))
  }

  if (action.type === `${IMPORT_RULES} ${API_ERROR}`) {
    dispatch(fetchDraftRules())
    dispatch(clearAlerts())
    dispatch(apiError('Fout bij import', action.payload))
  }
}

export const fetchOpdrachtnemersFlow = ({ dispatch }) => next => action => {
  next(action)

  if (action.type === FETCH_OPDRACHTNEMERS) {
    dispatch(apiRequest({ method:'GET', url:action.payload.url, feature:FETCH_OPDRACHTNEMERS }))
  }

  if (action.type === `${FETCH_OPDRACHTNEMERS} ${API_SUCCESS}`) {
    dispatch(clearAlerts())
    dispatch(storeOpdrachtnemers(action.payload))
  }

  if (action.type === `${FETCH_OPDRACHTNEMERS} ${API_ERROR}`) {
    dispatch(clearAlerts())
    dispatch(apiError('Fout bij ophalen samenwerkingsverbanden', action.payload))
  }
}
export const ruleMiddleware = [fetchRulesFlow, createRuleFlow, updateRuleFlow, deleteRuleFlow,
  activateRulesFlow, validateRulesFlow, resetRulesFlow, exportRulesFlow, fetchOpdrachtnemersFlow,
  importRulesFlow]
