import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ProgressBar } from 'react-bootstrap'

export function Progress({ now, max, text }) {
  return (
    <Modal.Dialog>
      <Modal.Header>
        <Modal.Title>Download CSV</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {text}
        <ProgressBar now={now} max={max} />
      </Modal.Body>
    </Modal.Dialog>
  )
}

Progress.propTypes = {
  now: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  text: PropTypes.string,
}

export default Progress
