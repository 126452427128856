import {
  CREATE_MESSAGE,
  FETCH_MESSAGES,
  UPDATE_MESSAGE,
  DELETE_MESSAGE,
  SEND_MESSAGE,
  DOWNLOAD_XML,
  storeMessage, storeMessages,
  storeRemoveMessage,
  undoStoreRemoveMessage,
} from '../actions/messages'
import {
  API_SUCCESS, API_ERROR,
} from '../actions/api'
import {
  addAlert,
  apiError,
  clearAlerts,
  info,
  success
} from '../actions/alerts'
import { apiRequest } from '../actions/api'
import {
  selectMessage,
  storeFileForDownload,
} from '../actions/page'

import { getFilenameForMessage } from '../selectors'

export const fetchMessagesFlow = ({ dispatch }) => next => action => {
  next(action)

  if (action.type === FETCH_MESSAGES) {
    dispatch(apiRequest({ method:'GET', url:action.payload.url, feature:FETCH_MESSAGES }))
  }

  if (action.type === `${FETCH_MESSAGES} ${API_SUCCESS}`) {
    dispatch(storeMessages(action.payload))
  }

  if (action.type === `${FETCH_MESSAGES} ${API_ERROR}`) {
    dispatch(clearAlerts())
    dispatch(apiError('Fout bij lezen van berichten', action.payload))
  }
}

export const createMessageFlow = ({ dispatch }) => next => action => {
  next(action)

  if (action.type === CREATE_MESSAGE) {
    dispatch(apiRequest({ method:'POST', url:action.payload.url, body: action.payload.message, feature: CREATE_MESSAGE }))
    dispatch(info('Bericht wordt opgeslagen'))
  }

  if (action.type === `${CREATE_MESSAGE} ${API_SUCCESS}`) {
    dispatch(storeMessage(action.payload))
    dispatch(selectMessage(action.payload._id))
    dispatch(clearAlerts())
  }

  if (action.type === `${CREATE_MESSAGE} ${API_ERROR}`) {
    dispatch(clearAlerts())
    dispatch(apiError('Fout bij opslaan van bericht', action.payload))
  }
}

export const updateMessageFlow = ({ dispatch }) => next => action => {
  next(action)

  if (action.type === UPDATE_MESSAGE) {
    dispatch(apiRequest({ method:'PUT', url:action.payload.url, body:action.payload.message, feature: UPDATE_MESSAGE }))
  }

  if (action.type === `${UPDATE_MESSAGE} ${API_SUCCESS}`) {
    dispatch(clearAlerts())
    dispatch(success('Bericht opgeslagen'))
    dispatch(storeMessage(action.payload))
  }

  if (action.type === `${UPDATE_MESSAGE} ${API_ERROR}`) {
    dispatch(clearAlerts())
    dispatch(apiError('Fout bij opslaan van bericht', action.payload))
  }
}

export const deleteMessageFlow = ({ dispatch }) => next => action => {
  next(action)

  if (action.type === DELETE_MESSAGE) {
    dispatch(storeRemoveMessage(action.payload.id))
    dispatch(apiRequest({ method:'DELETE', url:action.payload.url, feature: DELETE_MESSAGE }))
  }

  if (action.type === `${DELETE_MESSAGE} ${API_SUCCESS}`) {
    dispatch(clearAlerts())
  }

  if (action.type === `${DELETE_MESSAGE} ${API_ERROR}`) {
    dispatch(clearAlerts())
    dispatch(undoStoreRemoveMessage())
    dispatch(apiError('Fout bij verwijderen van bericht', action.payload))
  }
}

export const sendMessageFlow = ({ dispatch }) => next => action => {
  next(action)

  switch (action.type) {
  case SEND_MESSAGE:
    const body = { opdrachtId:action.payload.opdrachtId, xml: action.payload.xml, withAttachments: action.payload.withAttachments }

    dispatch(addAlert({ type: 'info', message: 'Bericht wordt verstuurd naar DSP' }))
    dispatch(apiRequest({ method:'POST', url:action.payload.url, body, feature: SEND_MESSAGE }))
    break
  case `${SEND_MESSAGE} ${API_SUCCESS}`:
    dispatch(clearAlerts())
    dispatch(success('Bericht verstuurd naar DSP'))
    break
  case `${SEND_MESSAGE} ${API_ERROR}`:
    dispatch(clearAlerts())
    dispatch(apiError('Fout bij verzenden naar DSP', action.payload))
    break

  default:
      // noop
  }
}

export const downloadXmlFlow = ({ getState, dispatch }) => next => action => {
  next(action)

  switch (action.type) {
  case DOWNLOAD_XML:
    dispatch(apiRequest({ method:'GET', url:action.payload.url, feature:DOWNLOAD_XML }))
    break
  case `${DOWNLOAD_XML} ${API_SUCCESS}`:
    dispatch(clearAlerts())
    const filename = getFilenameForMessage(getState(), action.payload)

    dispatch(storeFileForDownload(filename, action.payload.xml))

    break
  case `${DOWNLOAD_XML} ${API_ERROR}`:
    dispatch(clearAlerts())
    dispatch(apiError('Fout bij ophalen van xml', action.payload))
    break
  default:
      // noop
  }
}

export const messageMiddleware = [fetchMessagesFlow, createMessageFlow, updateMessageFlow, sendMessageFlow, deleteMessageFlow, downloadXmlFlow]
