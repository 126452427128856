export const CLEAR_CSV = '[csv] Clear store'
export const STORE_CSV = '[csv] Update Store'

export const clearCsvRows = () => ({
  type: CLEAR_CSV,
  payload: {}
})

export const storeCsvRows = (fields, data) => ({
  type: STORE_CSV,
  payload: { fields, data }
})
