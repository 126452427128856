import {
  CREATE_CERTIFICATE,
  FETCH_CERTIFICATES,
  DELETE_CERTIFICATE,
  DOWNLOAD_CERTIFICATE,
  storeCertificate, storeCertificates,
  storeRemoveCertificate,
  undoStoreRemoveCertificate,
} from '../actions/certificates'
import {
  API_SUCCESS, API_ERROR,
} from '../actions/api'
import {
  // addAlert,
  apiError,
  clearAlerts,
  info,
} from '../actions/alerts'
import { apiRequest } from '../actions/api'
import {
  selectCertificate,
  storeFileForDownload,
} from '../actions/page'

export const fetchCertificatesFlow = ({ dispatch }) => next => action => {
  next(action)

  if (action.type === FETCH_CERTIFICATES) {
    dispatch(apiRequest({ method:'GET', url:action.payload.url, feature:FETCH_CERTIFICATES }))
  }

  if (action.type === `${FETCH_CERTIFICATES} ${API_SUCCESS}`) {
    dispatch(storeCertificates(action.payload))
  }

  if (action.type === `${FETCH_CERTIFICATES} ${API_ERROR}`) {
    dispatch(clearAlerts())
    dispatch(apiError('Fout bij lezen van certificaten', action.payload))
  }
}

export const createCertificateFlow = ({ dispatch }) => next => action => {
  next(action)

  if (action.type === CREATE_CERTIFICATE) {
    dispatch(apiRequest({ method:'POST', url:action.payload.url, body: action.payload.certificate, feature: CREATE_CERTIFICATE }))
    dispatch(info('Certificaat wordt opgeslagen'))
  }

  if (action.type === `${CREATE_CERTIFICATE} ${API_SUCCESS}`) {
    dispatch(storeCertificate(action.payload))
    dispatch(selectCertificate(action.payload._id))
    dispatch(clearAlerts())
  }

  if (action.type === `${CREATE_CERTIFICATE} ${API_ERROR}`) {
    dispatch(clearAlerts())
    dispatch(apiError('Fout bij opslaan van certificaat', action.payload))
  }
}

export const deleteCertificateFlow = ({ dispatch }) => next => action => {
  next(action)

  if (action.type === DELETE_CERTIFICATE) {
    dispatch(storeRemoveCertificate(action.payload.id))
    dispatch(apiRequest({ method:'DELETE', url:action.payload.url, feature: DELETE_CERTIFICATE }))
  }

  if (action.type === `${DELETE_CERTIFICATE} ${API_SUCCESS}`) {
    dispatch(clearAlerts())
  }

  if (action.type === `${DELETE_CERTIFICATE} ${API_ERROR}`) {
    dispatch(clearAlerts())
    dispatch(undoStoreRemoveCertificate())
    dispatch(apiError('Fout bij verwijderen van certificaat', action.payload))
  }
}

export const downloadCertificateFlow = ({ getState, dispatch }) => next => action => {
  next(action)

  switch (action.type) {
  case DOWNLOAD_CERTIFICATE:
    dispatch(apiRequest({ method:'GET', url:action.payload.url, feature:DOWNLOAD_CERTIFICATE }))
    break
  case `${DOWNLOAD_CERTIFICATE} ${API_SUCCESS}`:
    dispatch(clearAlerts())

    const { party, system, name, version, content } = action.payload

    const filename = `${party} - ${system} - ${version} - ${name}`

    dispatch(storeFileForDownload(filename, content))

    break
  case `${DOWNLOAD_CERTIFICATE} ${API_ERROR}`:
    dispatch(clearAlerts())
    dispatch(apiError('Fout bij ophalen van certificaat', action.payload))
    break
  default:
      // noop
  }
}

export const certificateMiddleware = [fetchCertificatesFlow, createCertificateFlow, deleteCertificateFlow, downloadCertificateFlow]
