import { applyMiddleware } from 'redux'

import { api } from './api'
import { certificateMiddleware } from './certificates'
import { endpointMiddleware } from './endpoints'
import { messageMiddleware } from './messages'
import { opdrachtStatusMiddleware } from './opdrachtstatus'
import { csvMiddleware } from './csv'
import { werkbakMiddleware } from './werkbakken'
import { valuelistsMiddleware } from './valuelists'
import { pageMiddleware } from './page'
import { socketMiddleware } from './socket-io'
import { templateMiddleware } from './templates'
import { trackerMiddleware } from './tracker'
import { authMiddleware } from './auth'
import { ruleMiddleware } from './rules'

export default applyMiddleware(
  api,
  ...authMiddleware,
  socketMiddleware,
  ...pageMiddleware,
  ...templateMiddleware,
  ...messageMiddleware,
  ...opdrachtStatusMiddleware,
  ...csvMiddleware,
  ...werkbakMiddleware,
  ...valuelistsMiddleware,
  ...endpointMiddleware,
  ...certificateMiddleware,
  ...trackerMiddleware,
  ...ruleMiddleware,
)
