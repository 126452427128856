import {
  DropdownButton,
  MenuItem,
  Button,
  ControlLabel,
  FormControl,
  FormGroup,
  HelpBlock,
  Modal,
} from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import React from 'react'

import PropTypes from 'prop-types'

export class MessageForm extends React.Component {
  static propTypes = {
    message: PropTypes.shape({
      _id: PropTypes.string,
      description: PropTypes.string.isRequired,
      template: PropTypes.string.isRequired,
    })
  }


  static defaultState = {
    _id: undefined,
    description:'',
    file:'',
    template: '',
    discipline: '',
    submitted: false,
    xml: '',
  }


  constructor(props) {
    super(props)
    this.state = { ...MessageForm.defaultState }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.message !== prevProps.message) {
      this.setState({ ...MessageForm.defaultState, ...this.props.message })
    }
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value.trimStart() })
  handleSelect = prop => value => this.setState({ [prop]: value })

  validateProp = prop => {
    const length = this.state[prop].length

    if (length > 0) {
      return 'success'
    }

    return this.state.submitted ? 'danger' : null
  }

  validateFile = () => {
    const { file } = this.state

    if (file && file.name) {
      return 'success'
    }

    return this.state.submitted ? 'danger' : null
  }

  onDrop = (acceptedFiles, rejectedFiles) => (acceptedFiles.length > 0) && this.setState({ file:acceptedFiles[0] })

  readFile = () => new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () =>  resolve(reader.result)
    reader.onabort = () => reject('file reading was aborted')
    reader.onerror = () => reject('file reading has failed')

    reader.readAsBinaryString(this.state.file)
  })

  prepareMessage = xml => {
    const { _id, description, template, discipline } = this.state

    return { _id, description, template, discipline, xml }
  }

  onSubmit = () => {
    const isValid = !!this.state.file.name
      && ['description', 'discipline']
        .map(this.validateProp)
        .reduce((acc, cur) => (acc && cur === 'success'), true)

    if (!isValid)
      return this.setState({ submitted: true })

    this.readFile()
      .then(this.prepareMessage)
      .then(this.props.onSubmit)
      .then(this.resetState)
  }


 updateTemplateState = response => {
   const data = response.data || []
   const newState = { types:data }

   if (this.state.version === this.state.message.version) {
     newState.type = data.find(d => d._id === this.state.message._id) || {}
   }

   this.setState(newState)
 }


 render() {
   const { _id, description, file, discipline } = this.state

   return (
     this.props.show
       ?  <div className="static-modal">
         <Modal.Dialog>
           <Modal.Header>
             <Modal.Title>{_id ? 'Bericht bewerken' : 'Bericht aanmaken'}</Modal.Title>
           </Modal.Header>
           <Modal.Body>
             <form>

               <FormGroup controlId="description" validationState={this.validateProp('description')}>
                 <ControlLabel>Omschrijving</ControlLabel>
                 <FormControl
                   type="text"
                   name="description"
                   value={description}
                   placeholder="Berichtomschrijving"
                   onChange={this.handleChange}
                 />
                 <FormControl.Feedback />
                 <HelpBlock>Omschrijving van het bericht</HelpBlock>
               </FormGroup>

               <FormGroup controlId="discipline" validationState={this.validateProp('discipline')}>
                 <ControlLabel>Discipline</ControlLabel><br/>
                 <DropdownButton id="discipline" title={this.props.disciplines[discipline] || 'Selecteer Discipline'}
                   onSelect={this.handleSelect('discipline')} bsStyle={this.validateProp('discipline') || 'default'}>
                   { Object.entries(this.props.disciplines).map(([ k,v ]) =>
                     <MenuItem key={k} eventKey={k} active={discipline === k}>{v}</MenuItem>
                   )}
                 </DropdownButton>
                 <FormControl.Feedback />
               </FormGroup>

               <FormGroup controlId="message" validationState={this.validateFile()}>
                 <ControlLabel>XML bericht</ControlLabel>
                 <Dropzone onDrop={this.onDrop} multiple={false} name="message"
                   accept="text/xml" className="form-control"
                   style={{ height: '100%' }}
                   activeClassName="primary" acceptClassName="success" rejectClassName="error" >

                   <HelpBlock>Sleep hier 1 xml bericht naartoe</HelpBlock>
                   {
                     file.name &&
                      <div>Geselecteerd: <strong>{file.name}</strong></div>
                   }
                 </Dropzone>
                 <FormControl.Feedback />
               </FormGroup>
             </form>
           </Modal.Body>
           <Modal.Footer>
             <Button onClick={this.props.onClose}>Sluiten</Button>
             <Button bsStyle="success" className="pullRight" onClick={this.onSubmit}>Opslaan</Button>
           </Modal.Footer>
         </Modal.Dialog>
       </div>
       : false
   )
 }
}

export default MessageForm
