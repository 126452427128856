export const STORE_WERKBAKKEN   = '[werkbakken] Update store'
export const SEARCH_WERKBAKKEN  = '[werkbakken] Search'


const url = '/rest/opdracht/werkbakken'

export const searchWerkbakken = (query) => ({
  type: SEARCH_WERKBAKKEN,
  payload: {
    url,
    query,
  }
})

export const storeWerkbakken = items => ({
  type: STORE_WERKBAKKEN,
  payload: { items }
})
