import { connect } from 'react-redux'
import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { fetchMessages } from '../redux/actions/messages'
import { fetchTemplates } from '../redux/actions/templates'
import {
  getSelectedOpdrachtStatus,
  getOpdrachtStatuses,
  userHasScopes,
} from '../redux/selectors'
import {
  queryOpdrachtStatus,
  fetchOpdrachtStatus,
} from '../redux/actions/opdrachtstatus'
import {
  selectMessage,
  selectOpdrachtStatus,
} from '../redux/actions/page'
import OpdrachtStatus from './OpdrachtStatus'

export class OpdrachtStatusView extends Component {
  state = { query: '' }

  componentDidMount() {
    if (this.props.userHasScopes(['show:testtool'])) {
      this.props.fetchTemplates()
      this.props.fetchMessages()
    } else {
      // the setup of this component was such that we first retreived templates and message
      // if either of them got a result an update of this component was triggered
      // resulting in retreiving the opdrachtstatus
      // since we now only get the messages when a user has a testrole
      // the component didn't update and thus no opdrachtstatus was fetched either
      this.setState({ query:null })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const query = this.props.match && this.props.match.params.query

    if (query !== this.state.query) {
      this.setState({ query })

      if (!query) {
        this.props.selectOpdrachtStatus()

        if (this.props.userHasScopes(['show:testtool']))
          this.props.fetchOpdrachtStatus()
      }
    }
  }

  navigate = query => {
    if (query === this.state.query)
      return

    this.props.selectMessage()
    if (query.length > 0)
      this.props.history.push(`/status/${query}`)
    else
      this.props.history.push('/status')
  }

  render() {
    const { query } = this.state
    const { userHasScopes } = this.props

    return <OpdrachtStatus key={query} query={query} {...this.props} onSearch={this.navigate}
      showTest={ userHasScopes(['show:testtool']) }
      showNieuweOpdracht = { userHasScopes(['show:testtool', 'read:sender_netbeheerder']) }
    />
  }
}

OpdrachtStatusView.propTypes = {
  fetchTemplates: PropTypes.func.isRequired,
  fetchMessages: PropTypes.func.isRequired,
}

const mapStateToProps = (state) =>  ({
  selectedOpdrachtStatus : getSelectedOpdrachtStatus(state),
  opdrachtstatuses       : getOpdrachtStatuses(state),
  userHasScopes          : userHasScopes(state),
})

export default connect(mapStateToProps, {
  queryOpdrachtStatus,
  fetchOpdrachtStatus,
  fetchTemplates,
  fetchMessages,
  selectMessage,
  selectOpdrachtStatus,
})(OpdrachtStatusView)
