import {
  SELECT_TEMPLATE,
  SELECT_VERSION,
  SELECT_MESSAGE,
  SELECT_OPDRACHT_STATUS,
  SELECT_CERTIFICATE,
  SELECT_PARTY,
  ADD_DOWNLOAD_FILE,
  REMOVE_DOWNLOAD_FILE,
  SELECT_OPDRACHTNEMER,
  SET_ADV_SEARCH,
} from '../actions/page'
import { getMessageById } from './messages'
import { getOpdrachtStatusById } from './opdrachtstatus'
import { getTemplateById } from './templates'

const initialState = {
  selectedTemplateId: '',
  selectedMessageId: '',
  selectedVersion: '',
  selectedId: '',
  selectedParty: '',
  selectedOpdrachtnemer: '',
  downloadFile: null,
  advSearch: {},
}


export default function pageReducer(state = initialState, action) {
  switch (action.type) {
  case SELECT_TEMPLATE:
    return { ...state, selectedTemplateId: action.payload.templateId }

  case SELECT_MESSAGE:
    return { ...state, selectedMessageId: action.payload.messageId }

  case SELECT_VERSION:
    return { ...state, selectedVersion: action.payload.version }

  case SELECT_CERTIFICATE:
    return { ...state, selectedCertificateId: action.payload.certificateId }

  case SELECT_OPDRACHT_STATUS:
    return { ...state, selectedId: action.payload.id }

  case SELECT_PARTY:
    return { ...state, selectedParty: action.payload.party }

  case SELECT_OPDRACHTNEMER:
    return { ...state, selectedOpdrachtnemer: action.payload.opdrachtnemer }

  case ADD_DOWNLOAD_FILE:
    return { ...state, downloadFile: action.payload }

  case REMOVE_DOWNLOAD_FILE:
    return { ...state, downloadFile: null }

  case SET_ADV_SEARCH:
    return { ...state, advSearch: action.payload.advSearch || [] }

  default:
    return state
  }
}


/** Get selected template id */
export const getSelectedTemplateId = state => state.page.selectedTemplateId
/** Get selected message id */
export const getSelectedMessageId  = state => state.page.selectedMessageId
/** Get selected opdrachtstatus id */
export const getSelectedId = state => state.page.selectedId
/** Get selected version */
export const getSelectedVersion = state => state.page.selectedVersion
/** Get selected certificate */
export const getSelectedCertificateId = state => state.page.selectedCertificateId
/** Get selected party */
export const  getSelectedParty = state => state.page.selectedParty
/** Get selected opdrachtnemer */
export const getSelectedOpdrachtnemer = state => state.page.selectedOpdrachtnemer

/** Get the currently selected template */
export const getSelectedTemplate = state => getTemplateById(state, getSelectedTemplateId(state))
/** Get the currently selected message */
export const getSelectedMessage = state => getMessageById(state, getSelectedMessageId(state))
/** Get the currently selected opdrachtstatus */
export const getSelectedOpdrachtStatus = state => getOpdrachtStatusById(state, getSelectedId(state))

/** Get a file for download */
export const getFileToDownload = state => state.page.downloadFile ?  ({ ...state.page.downloadFile }) : null


/** get uitgebreid zoeken */
export const getAdvSearch = state => state.page.advSearch

