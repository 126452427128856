import {
  Glyphicon, Table,
  DropdownButton, MenuItem,
} from 'react-bootstrap'
import React from 'react'

import PropTypes from 'prop-types'

function TemplateTable(props) {
  return (
    <Table striped bordered condensed hover>
      <thead>
        <tr>
          <th>Berichttemplate</th>
          <th>Verzender</th>
          <th>Status</th>
          <th>
            <DropdownButton id="versions" title={props.version || 'Alle'} onSelect={props.onSelectVersion} bsSize="xsmall">
              <MenuItem key={'all'} eventKey='' active={props.version === ''}>Alle</MenuItem>
              { props.versions && props.versions.map(v =>
                <MenuItem key={v} eventKey={v} active={props.version === v}>{v}</MenuItem>
              )}
            </DropdownButton>
          </th>
          <th width="150">Aangemaakt</th>
          <th width="150">Gewijzigd</th>
          <th><span title="aantal berichten">#</span></th>
          <td colSpan={2} align="center">
            <a href="templates/create" title="Nieuw" onClick={e => {e.preventDefault(); props.onCreate()}}>
              <Glyphicon glyph="plus" />
            </a>
          </td>
        </tr>
      </thead>
      <tbody>{props.children}</tbody>
    </Table>
  )
}


TemplateTable.propTypes = {
  versions: PropTypes.array.isRequired,
  version: PropTypes.string.isRequired,
  onCreate: PropTypes.func.isRequired,
  onSelectVersion: PropTypes.func.isRequired,
}

TemplateTable.defaultProps = {
  versions: [],
  version: '',
}

export default TemplateTable
