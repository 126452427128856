/* global process */
import io from 'socket.io-client'
import React from 'react'

import { CONNECT_SOCKET, connectSocket } from '../actions/socket-io'
import { debounce } from '../../util'
import { info, error, clearAlerts } from '../actions/alerts'
import { getAccessToken, getOpdrachtStatusById, userHasScopes } from '../selectors'
import { storeOpdrachtStatus } from '../actions/opdrachtstatus'

const DELAY = 500 // ms

const isTest = state => userHasScopes(state)(['show:testtool'])

const createSocketMiddleware = () => {
  let socket

  function connect_({ getState, dispatch }, action) {
    if (process.env.REACT_APP_DISABLE_SOCKET) {
      dispatch(info(<code>REACT_APP_DISABLE_SOCKET=true</code>, 'Automatic updates have been disabled'))

      return
    }

    const query = { token: getAccessToken(getState()) }
    const namespace = action.payload.namespace.replace(/ /g, '')

    if (socket && socket.connected) {
      socket.close()
    }

    socket = io(`${namespace}`, { query, path: '/stream/socket.io' })
    socket.on('connect', () => {
      socket.on('update opdrachtstatus', data => {
        const inHuidigeLijst = getOpdrachtStatusById(getState(), data._id)  !== undefined
        const nieuweOpdracht = data.status === 200 // eslint-disable-line

        if (inHuidigeLijst || nieuweOpdracht) {
          dispatch(storeOpdrachtStatus(data))
        }
      })
    })

    socket.on('error', err => {
      socket.close()
      if (err && err.indexOf && err.indexOf('TokenExpiredError') > -1) {
        dispatch(connectSocket(action.payload.deelnemer))
      } else {
        dispatch(clearAlerts())
        dispatch(error('Kan geen verbinding maken voor automatische updates'))
      }
    })
  }

  const connect = debounce(connect_, DELAY)

  return store => next => action => {
    next(action)

    if (action.type === CONNECT_SOCKET && isTest(store.getState())) {
      connect(store, action)
    }
  }
}

export const socketMiddleware = createSocketMiddleware()
