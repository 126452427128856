import { createSelector } from 'reselect'

import {
  STORE_WERKBAKKEN,
} from '../actions/werkbakken'

const initialState = {
  items: []
}



export default function werkbakReducer(state = initialState, action) {
  switch (action.type) {
  case STORE_WERKBAKKEN:
    const items = action.payload.items.reduce((dict, werkbak) => {
      dict[werkbak.code] = werkbak

      return dict
    }, {})

    return { ...state, items }

  default:
    return state
  }
}

const werkbakken = state => state.werkbakken.items

export const getWerkbakken = createSelector(
  werkbakken,
  werkbakken => Object.values(werkbakken)
)

export const getWerkbak = (state,code) => {
  const wbs = werkbakken(state)
  const wb = wbs  && wbs[code]

  return wb && { ...wb }
}

