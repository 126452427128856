import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Glyphicon } from 'react-bootstrap'
import { connect } from 'react-redux'
import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import { downloadXml } from '../redux/actions/messages'
import { formatDateTime  } from '../util'
import { getTemplateById, getDisciplineLabel } from '../redux/selectors'

export function MessageRow(props)  {
  const { _id, description, created, updated  } = props.message

  const onSelect = e => {e.preventDefault(); props.onSelect(_id)   }
  const onEdit   = e => {e.preventDefault(); props.onEdit(_id)     }
  const onDelete = e => {e.preventDefault(); props.onDelete(_id)   }
  const download = e => {e.preventDefault(); props.downloadXml(_id)}

  return (
    <tr onClick={onSelect} className={(props.selected) ? 'selected' : ''}>
      <td>{description}</td>
      <td>{props.disciplineLabel}</td>

      { !!props.editMode ?
        <Fragment>
          <td>{formatDateTime(created)}</td>
          <td>{formatDateTime(updated)}</td>
        </Fragment>
        :
        <td>{props.version}</td>
      }

      <td align="center" style={{ width: '32px' }}>
        <a href={`/messages/${_id}/xml/`} title="XML downloaden" onClick={download}>
          <FontAwesomeIcon icon="download" />
        </a>
      </td>

      { !!props.editMode &&
          <Fragment>
            <td align="center" style={{ width: '32px' }}>
              <a href={'delete($_id)'} title="Verwijderen" onClick={onDelete}>
                <Glyphicon glyph="remove" />
              </a>
            </td>
            <td align="center" style={{ width: '32px' }}>
              <a href={`messages/edit(${_id})`} title="Bewerken"
                onClick={onEdit}>
                <FontAwesomeIcon icon="edit" />
              </a>
            </td>
          </Fragment>
      }
    </tr>
  )
}

MessageRow.propTypes = {
  onSelect     : PropTypes.func.isRequired,
  onEdit       : PropTypes.func,
  onDelete     : PropTypes.func,
  downloadXml  : PropTypes.func,
  editMode     : PropTypes.bool,
  message      : PropTypes.shape({
    _id          : PropTypes.string.isRequired,
    description  : PropTypes.string.isRequired,
  }),
}

MessageRow.defaultProps = {
  editMode : false,
}

const mapStateToProps = () => (state, ownProps) => ({
  disciplineLabel : getDisciplineLabel(state, ownProps.message.discipline),
  version         : getTemplateById(state, ownProps.message.template).version,
})

export default connect(mapStateToProps, { downloadXml })(MessageRow)
