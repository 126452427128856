import auth0 from 'auth0-js'
import history from '../../history'

import {
  AUTH_LOGIN,
  AUTH_LOGIN_HANDLE,
  AUTH_LOGOUT,
  AUTH_ADD_SESSION_TIMER,
  FETCH_PROFILE,
  storeProfile,
} from '../actions/auth'

import {
  getAccessToken,
  getExpirationTime,
} from '../selectors'

import { warning, error } from '../actions/alerts'

import {
  loginSuccess,
  loginFailure,
} from '../actions/auth'

const requested_scopes = [
  'create:additional_xml',
  'create:lip',
  'create:swv',
  'delete:swv',
  'edit:swv',
  'read:sender_netbeheerder',
  'read:sender_aannemer',
  'show:advsearch',
  'show:connectivity',
  'show:maintenance',
  'show:opdrachtstatus',
  'show:swv',
  'show:swv-beheer',
  'show:testtool',
  'show:tracker',
  'show:werkbakken',
]

const { hostname, origin } = window.location
const options = {
  clientID: 'eNQjyypcPr5HZ0NB6HD1nhtI4PBKee50',
  domain: 'dspanalytics.eu.auth0.com',
  redirectUri: 'https://analytics.dsplatform.nl/callback',
  returnTo: 'https://analytics.dsplatform.nl/home',
  audience: 'https://analytics.dsplatform.nl/api',
  responseType: 'token id_token',
  scope: `openid profile email ${requested_scopes.join(' ')}`,
}

if (hostname.indexOf('dev-analytics.dsplatform.nl') > -1 ||   // TODO kan dit met env? of api service??
  hostname.indexOf('tst-analytics.dsplatform.nl') > -1 ||
  hostname.match(/qas-analytics(-.+)?\.dsplatform\.nl/) ||
  hostname.indexOf('vm-analytics.dsplatform.nl') > -1 ||
  hostname.indexOf('vm-analytics.dsplatform.nl') > -1 ||
  hostname.indexOf('localhost') > -1) {

  options.clientID = 'OHuXtoNE001v8hAsvCmuHU1X2HOdcC3c'
  options.domain = 'test-dspanalytics.eu.auth0.com'
  options.audience = 'https://analytics.dsplatform.nl/api'
  options.returnTo = origin + '/home'
  options.redirectUri = origin + '/callback'
} else if (hostname.match(/prd-analytics(-.+)?\.dsplatform\.nl/)) {
  options.returnTo = origin + '/home'
  options.redirectUri = origin + '/callback'
}


const gotoPreviousPage = () => {
  if (localStorage.hasOwnProperty('backTo')) {
    history.replace(localStorage.getItem('backTo'))
    localStorage.removeItem('backTo')
  } else {
    history.replace('/home')
  }
}

export const loginFlow = auth => ({ dispatch }) => next => action => {
  next(action)


  if (action.type === AUTH_LOGIN) {
    localStorage.setItem('backTo', window.location.pathname)
    auth.authorize()
  }

  if (action.type === AUTH_LOGIN_HANDLE) {
    auth.parseHash((err, authResult) => {
      gotoPreviousPage()
      if (err) {
        dispatch(loginFailure())
        dispatch(error(err, 'Inloggen mislukt'))
        console.log(err) // eslint-disable-line
      } else if (authResult && authResult.accessToken && authResult.idToken) {
        dispatch(loginSuccess(authResult))
      } else {
        dispatch(loginFailure())
        dispatch(error('Inloggen mislukt'))
      }
    })
  }
}

export const fetchProfileFlow = auth => ({ getState, dispatch }) => next => action => {
  next(action)

  if (action.type === FETCH_PROFILE) {
    const accessToken = getAccessToken(getState())

    auth.client.userInfo(accessToken, (err, profile) => {
      if (!profile || err) {
        dispatch(warning('Kon profiel niet laden'))
      } else {
        dispatch(storeProfile(profile))
      }
    })

  }
}


export const sessionFlow = auth => {

  let tokenRenewalTimeout = undefined

  return ({ getState, dispatch }) => next => action => {
    next(action)

    function renewToken() {
      localStorage.setItem('backTo', window.location.pathname)
      auth.checkSession({}, (err, result) => {
        if (err) {
          history.replace('/home')
        console.log(err) // eslint-disable-line
        } else {
          dispatch(loginSuccess(result))
        }
      })
    }

    if (action.type === AUTH_ADD_SESSION_TIMER) {
      if (tokenRenewalTimeout) {
        clearTimeout(tokenRenewalTimeout)
        tokenRenewalTimeout = null
      }

      const expiresAt = getExpirationTime(getState())
      const delay = expiresAt - Date.now()

      if (delay > 0) {
        tokenRenewalTimeout = setTimeout(renewToken, delay)
      }
    }

    if (action.type === AUTH_LOGOUT) {
      if (tokenRenewalTimeout) {
        clearTimeout(tokenRenewalTimeout)
        tokenRenewalTimeout = null
      }
      // history.replace('/home')
      auth.logout({
        returnTo: options.returnTo
      })
    }
  }
}

export const authMiddleware = (function () {
  const auth = new auth0.WebAuth(options)

  return [loginFlow(auth), fetchProfileFlow(auth), sessionFlow(auth)]
}())
