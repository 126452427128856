import { createSelector } from 'reselect'


import {
  getDisciplineLabel,
} from './reducers/valuelists'
import {
  getMessages,
  getMessagesByTemplateId,
  getMessageCountByTemplateId,
  filterMessageByTemplateIds,
  filterMessageByDiscipline,
  formatMessageFilename,
} from './reducers/messages'
import {
  getSelectedTemplateId,
  getSelectedVersion,
  getSelectedOpdrachtStatus,
  getSelectedMessage,
} from './reducers/page'
import {
  getTemplates,
  getSortedTemplates,
  isOpdrachtTemplate,
  filterTemplateByVersion,
  filterTemplateByStatus,
} from './reducers/templates'
import { messageComparator } from '../util'

export * from './reducers/auth'
export * from './reducers/templates'
export * from './reducers/messages'
export * from './reducers/opdrachtstatus'
export * from './reducers/alerts'
export * from './reducers/valuelists'
export * from './reducers/page'
export * from './reducers/endpoints'
export * from './reducers/certificates'
export * from './reducers/tracker'
export * from './reducers/werkbakken'
export * from './reducers/rules'

/** Get an array of templates
 * The array is sorted and filtered by selected version.
 * Or not filtered if there is no selected version
 */
export const getSortedTemplatesBySelectedVersion = createSelector(
  getSortedTemplates, getSelectedVersion,
  (templates, version) => version
    ? templates.filter(filterTemplateByVersion(version))
    : templates
)

/** Get messages filtered by the id of the selected template */
export const getMessagesBySelectedTemplate = createSelector(
  getMessages, getSelectedTemplateId,
  getMessagesByTemplateId
)

/** Get messages filterd by status */
export const getMessagesByStatus = (templates, messages, status) => {
  const templateIds = templates.filter(filterTemplateByStatus(status)).map(({ _id }) => _id)

  const filteredMessages = messages.filter(filterMessageByTemplateIds(templateIds))
    .map(m => ({ ...m, version: templates.find(t => t._id === m.template).version }))
    .sort((m1, m2) => {
      if (m1.version === m2.version) {
        return (m1.discipline < m2.discipline) ? -1 : 1
      }

      return m1.version < m2.version ? -1 : 1
    })

  return filteredMessages
}

export const getMessagesByOpdrachtStatus = (templates = [], messages = [], os) => {
  if (!os) return []
  if (os.netbeheerder !== 'DSP' && os.aannemer !== 'DSP')
    return []

  const filterByDiscipline = t =>
    filterMessageByDiscipline('-')(t) || filterMessageByDiscipline(os.discipline)(t)

  const templateIds = templates
    .filter(filterTemplateByVersion(os.versieNummer))
    .filter(filterTemplateByStatus(os.status))
    .map(({ _id }) => _id)

  return messages.filter(filterByDiscipline).filter(filterMessageByTemplateIds(templateIds)).sort(messageComparator)
}


/** Get messages filtered by the status of the selected OpdrachtStatus */
export const getMessagesBySelectedOpdrachtStatus = createSelector(
  getTemplates, getMessages, getSelectedOpdrachtStatus,
  (templates, messages, opdrachtStatus) => getMessagesByOpdrachtStatus(templates, messages, opdrachtStatus),
)

/** Get messages filtered by the status of the selected OpdrachtStatus */
export const getOpdrachtMessages = createSelector(
  getTemplates, getMessages,
  (templates, messages) => getMessagesByStatus(templates, messages, 0),
)

/** Check if selected message is an opdracht */
export const isOpdrachtMessageSelected = state => {
  const message = getSelectedMessage(state)

  if (!message) return false

  return isOpdrachtTemplate(state, message.template)
}

/** Get templates with message count */
export const getMessageCount = createSelector(
  getTemplates, getMessages,
  (templates, messages) => templates.map(
    t => ({ ...t, messageCount: getMessageCountByTemplateId(messages, t._id) })
  )
)

export const makeGetMessageCountByTemplateId = () => createSelector(
  (state) => getMessages(state),
  (state, props) => props.template._id,
  getMessageCountByTemplateId
)

export const getFilenameForMessage = (state, message) =>
  formatMessageFilename(message, getDisciplineLabel(state, message.discipline))

/** Get connections for given id */
export const getConnectionsById = (state, id) => state.connections.items[id]

export const getProgress = state => state.progress
