import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Glyphicon, Table } from 'react-bootstrap'

const MessageTable = ({ children, editmode, onCreate }) =>
  <Table striped bordered condensed hover>
    <thead>
      <tr>
        <th>Bericht</th>
        <th>Discipline</th>
        { !!editmode ?
          <Fragment>
            <th width="150">Aangemaakt</th>
            <th width="150">Gewijzigd</th>
            <td colSpan={3} align="center">
              <a href="messages/create" title="Nieuw" onClick={e => {e.preventDefault(); onCreate()}}>
                <Glyphicon glyph="plus"/>
              </a>
            </td>
          </Fragment>
          :
          <th>Versie</th>
        }
      </tr>
    </thead>
    <tbody>
      { children }
    </tbody>
  </Table>

MessageTable.propTypes = {
  editmode: PropTypes.bool,
  onCreate: PropTypes.func,
}

MessageTable.defaultProps = {
  editmode: false,
}

export default MessageTable
