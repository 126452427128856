import {
  STORE_CERTIFICATE,
  STORE_CERTIFICATES,
  STORE_REMOVE_CERTIFICATE,
  UNDO_REMOVE_CERTIFICATE,
} from '../actions/certificates'

const initialState = {
  items: {},
  removed: [],
}

export default function certificateReducer(state = initialState, action) {
  switch (action.type) {
  case STORE_CERTIFICATES:
    const items = action.payload.items.reduce((acc, t) => {
      acc[t._id] = t

      return acc
    }, {})

    return { ...state, items }

  case STORE_CERTIFICATE:
    const certificate = (action.payload && action.payload.certificate)

    return { ...state, items: { ...state.items, [certificate._id]: certificate } }

  case STORE_REMOVE_CERTIFICATE:
    const { [action.payload.id]:removedCertificate, ...newItems } = state.items

    return { ...state, items:newItems, removed: [...state.removed, removedCertificate] }

  case UNDO_REMOVE_CERTIFICATE:
    if (state.removed.length === 0)
      return state

    const removed = state.removed.slice(0)
    const undo = removed.pop()

    return { ...state, items: { ...state.items, [undo._id]: undo }, removed }


  default:
    return state
  }
}

export const getCertificates = state => Object.values(state.certificates.items)
export const getCertificatesBySystem = (state, system) => Object.values(state.certificates.items).filter(_ => _.system === system)
