import {
  CREATE_TEMPLATE,
  FETCH_TEMPLATES,
  UPDATE_TEMPLATE,
  storeTemplate,
  storeTemplates,
} from '../actions/templates'
import {
  API_SUCCESS, API_ERROR,
} from '../actions/api'
import { apiError, clearAlerts, success } from '../actions/alerts'
import { apiRequest } from '../actions/api'
import { selectTemplate } from '../actions/page'

export const fetchTemplatesFlow = ({ dispatch }) => next => action => {
  next(action)

  if (action.type === FETCH_TEMPLATES) {
    dispatch(apiRequest({ method:'GET', url:action.payload.url, feature: FETCH_TEMPLATES }))
  }

  if (action.type === `${FETCH_TEMPLATES} ${API_SUCCESS}`) {
    dispatch(storeTemplates(action.payload))
  }

  if (action.type === `${FETCH_TEMPLATES} ${API_ERROR}`) {
    dispatch(clearAlerts())
    dispatch(apiError('Fout bij lezen van templates', action.payload))
  }
}

export const createTemplateFlow = ({ dispatch }) => next => action => {
  next(action)

  if (action.type === CREATE_TEMPLATE) {
    const body = action.payload.template

    if (body.status.split) {
      body.status = body.status.split(',')
      body.status.sort()
    }

    dispatch(apiRequest({ method: 'POST', url:action.payload.url, body, feature: CREATE_TEMPLATE }))
  }

  if (action.type === `${CREATE_TEMPLATE} ${API_SUCCESS}`) {
    dispatch(storeTemplate(action.payload))
    dispatch(selectTemplate(action.payload._id))
    dispatch(success('Bericht opgeslagen'))
  }

  if (action.type === `${CREATE_TEMPLATE} ${API_ERROR}`) {
    dispatch(clearAlerts())
    dispatch(apiError('Fout bij opslaan van template', action.payload))
  }
}

export const updateTemplateFlow = ({ dispatch }) => next => action => {
  next(action)

  if (action.type === UPDATE_TEMPLATE) {
    const body = action.payload.template

    if (body.status.split) {
      body.status = body.status.split(',')
      body.status.sort()
    }
    dispatch(apiRequest({ method: 'PUT', url:action.payload.url, body, feature: UPDATE_TEMPLATE }))
  }

  if (action.type === `${UPDATE_TEMPLATE} ${API_SUCCESS}`) {
    dispatch(storeTemplate(action.payload))
  }

  if (action.type === `${UPDATE_TEMPLATE} ${API_ERROR}`) {
    dispatch(clearAlerts())
    dispatch(apiError('Fout bij opslaan van template', action.payload))
  }
}

export const templateMiddleware = [fetchTemplatesFlow, createTemplateFlow, updateTemplateFlow]
