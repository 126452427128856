export const CREATE_CERTIFICATE       = '[certificate] Create'
export const DELETE_CERTIFICATE       = '[certificate] Delete'
export const  FETCH_CERTIFICATES      = '[certificates] Fetch'

export const STORE_CERTIFICATES       = '[certificates] Update store'
export const STORE_CERTIFICATE        = '[certificate] Update store'
export const STORE_REMOVE_CERTIFICATE = '[certificate] Remove from store'
export const  UNDO_REMOVE_CERTIFICATE = '[certificate] Undo Remove from store'

export const DOWNLOAD_CERTIFICATE      = '[certificate] Download'

const url = '/rest/connectivity/certificates'

export const fetchCertificates = ({ party, version }) => ({
  type: FETCH_CERTIFICATES,
  payload: {
    url: `${url}/${party}/${version}`,
  }
})

export const storeCertificates = items => ({
  type: STORE_CERTIFICATES,
  payload: { items },
})

export const createCertificate = (certificate) => ({
  type: CREATE_CERTIFICATE,
  payload: {
    url: `${url}/${certificate.party}/${certificate.version}/${certificate.system}`,
    certificate,
  }
})

export const saveCertificate = createCertificate

export const storeCertificate = certificate => ({
  type: STORE_CERTIFICATE,
  payload: { certificate }
})

export const downloadCertificate = ({ party, version, system, _id:id }) => ({
  type: DOWNLOAD_CERTIFICATE,
  payload: {
    url: `${url}/${party}/${version}/${system}/${id}`,
  }
})

export const deleteCertificate = ({ party, system, version, _id:id }) => ({
  type: DELETE_CERTIFICATE,
  payload: {
    url: `${url}/${party}/${version}/${system}/${id}`,
    id,
  }
})

export const storeRemoveCertificate = id => ({
  type: STORE_REMOVE_CERTIFICATE,
  payload: { id }
})

export const undoStoreRemoveCertificate = () => ({
  type: UNDO_REMOVE_CERTIFICATE,
  payload: { }
})



