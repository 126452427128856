import uniqid from 'uniqid'
import { createSelector } from 'reselect'

import {
  ADD_ALERT,
  REMOVE_ALERT,
  CLEAR_ALERTS,
} from '../actions/alerts'

const initialState = {
  items: {},
}

export default function alertsReducer(state = initialState, action) {
  switch (action.type) {
  case ADD_ALERT:
    const id = uniqid()

    action.payload.id = id

    return { items: { ...state.items, [id]: action.payload } }
  case REMOVE_ALERT:
    const items = { ...state.items }

    delete items[action.payload.id]

    return { ...state, items }

  case CLEAR_ALERTS:
    return initialState

  default:
    return state
  }
}

/* ---- selector ---- */

/** Get direct reference to alerts in store */
const alerts = state => state.alerts.items

/** Get an array of alerts. */
export const getAlerts = createSelector(
  alerts,
  alerts => Object.values(alerts)
)
