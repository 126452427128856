import PaPa from 'papaparse'

import { DOWNLOAD_QUERY_OPDRACHT }             from '../actions/opdrachtstatus'
import { API_SUCCESS, API_ERROR, apiRequest }  from '../actions/api'
import { clearAlerts, apiError }               from '../actions/alerts'
import { clearCsvRows, storeCsvRows }          from '../actions/csv'
import { updateProgressbar, clearProgressbar } from '../actions/progress'
import { storeFileForDownload }                from '../actions/page'

export const downloadOpdrachtExportFlow = ({ getState, dispatch }) => next => action => {
  next(action)

  const downloadPage = ({ url, filters, filename, next }) =>
    dispatch(apiRequest({ method: 'POST', url,
      body: { filters, next },
      feature: DOWNLOAD_QUERY_OPDRACHT,
      props: { filename, filters, url }
    }))


  switch (action.type) {

  case DOWNLOAD_QUERY_OPDRACHT:
    dispatch(updateProgressbar(0, 1, action.payload.filename))
    dispatch(clearCsvRows())
    downloadPage(action.payload)
    break

  case `${DOWNLOAD_QUERY_OPDRACHT} ${API_SUCCESS}`:
    // const paging = action.payload.paging
    // dispatch(updateProgressbar(paging.page, paging.totalPages))

    const { header, docs, paging: { totalCount, currentCount, next, limit } } = action.payload

    dispatch(updateProgressbar((totalCount - currentCount + limit), totalCount))
    dispatch(storeCsvRows(header, docs))

    if (next) {
      downloadPage({ ...action.meta.props, next })
    } else {
      dispatch({ type:'[csv] Parse', payload:{ filename: action.meta.props.filename } })
    }
    break

  case `${DOWNLOAD_QUERY_OPDRACHT} ${API_ERROR}`:
    dispatch(clearAlerts())
    dispatch(clearCsvRows())
    dispatch(apiError('Fout bij opdrachten exporteren', action.payload))
    break

  case '[csv] Parse':
    const { fields, data } = getState().csv
    const csvData = PaPa.unparse({ fields, data },{ header:true, quotes: true })

    dispatch(clearProgressbar())
    dispatch(storeFileForDownload(action.payload.filename, csvData))
    break

  default:
      // noop
  }
}

export const csvMiddleware = [downloadOpdrachtExportFlow]
