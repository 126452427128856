import './navmenu.css'

import { AlertList } from 'react-bs-notifier'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import {
  Navbar, Nav, NavItem,
  NavDropdown, MenuItem,
  Glyphicon,
} from 'react-bootstrap'
import { connect } from 'react-redux'
import React from 'react'

import PropTypes from 'prop-types'

import { connectSocket } from '../redux/actions/socket-io'
import {
  fetchProfile,
  addSessionTimer,
} from '../redux/actions/auth'
import { login } from '../redux/actions/auth'
import { getAlerts, getFileToDownload,
  userHasScopes,
  getUserParty,
  getUserProfile,
  isAuthenticated,
  getExpirationTime,
  getProgress,
} from '../redux/selectors'
import { removeAlert } from '../redux/actions/alerts'
import { removeFileForDownload } from '../redux/actions/page'
import FileDownload from '../FileDownload'
import Progress from '../Progress'
import ProfileDropdown from './ProfileDropdown'

/**
 * Main navigation above all pages.
 *
 */
export class NavMenu extends React.Component {
  static propTypes = {
    /**  contains authentication related data and functions */
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.func.isRequired,
    userHasScopes: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { isAuthenticated, addSessionTimer, fetchProfile } = this.props

    if (isAuthenticated()) {
      addSessionTimer()
      if (!this.props.profile) {
        fetchProfile()
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isAuthenticated, connectSocket, addSessionTimer, fetchProfile } = this.props

    if (isAuthenticated()) {
      if (this.props.deelnemer && this.props.deelnemer !== prevProps.deelnemer) {
        connectSocket(this.props.deelnemer)
      }

      if (this.props.expiresAt && this.props.expiresAt !== prevProps.expiresAt) {
        addSessionTimer()
        fetchProfile()
      }
    }
  }

  render() {
    const props = this.props
    const { userHasScopes, login, isAuthenticated } = this.props

    return (
      <Navbar collapseOnSelect>
        <Navbar.Header>
          <Navbar.Brand>
            <Link to="/">DSP Analytics</Link>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav>
            {
              userHasScopes(['show:opdrachtstatus']) &&

                <NavDropdown title="Opdracht Status" id="dashboard-dropdown">
                  <LinkContainer to="/status">
                    <MenuItem>Zoeken opdracht ID</MenuItem>
                  </LinkContainer>

                  { /* userHasScopes(['show:advsearch']) &&
                  <LinkContainer to="/zoeken">
                    <MenuItem>Uitgebreid zoeken</MenuItem>
                  </LinkContainer> */
                  }
                </NavDropdown>
            }

            {
              userHasScopes(['show:maintenance']) &&
              <LinkContainer to="/maintenance">
                <NavItem>Onderhoud</NavItem>
              </LinkContainer>
            }

            { /* userHasScopes(['show:connectivity']) &&
              <LinkContainer to="/connections">
                <NavItem>Connectiviteit</NavItem>
              </LinkContainer>
            */ }

            { /* userHasScopes(['show:tracker']) &&
                <LinkContainer to="/tracker">
                  <NavItem>Tracker</NavItem>
                </LinkContainer> */
            }

            { /* userHasScopes(['show:swv']) &&
                <LinkContainer to="/samenwerking">
                  <NavItem>Samenwerkingsverband</NavItem>
                </LinkContainer> */
            }
          </Nav>

          <Nav pullRight>
            { isAuthenticated()
              ? <ProfileDropdown />
              : <NavItem onClick={login}><Glyphicon glyph="lock" /> Inloggen </NavItem>
            }
          </Nav>
        </Navbar.Collapse>
        <AlertList position="bottom-right" onDismiss={props.removeAlert} alerts={props.alerts} />

        {props.file &&
            <FileDownload
              key={props.file.filename}
              {...props.file}
              done={props.removeFileForDownload}
            />
        }

        {props.progress && (props.progress.now >= 0) && <Progress {...props.progress} /> }
      </Navbar>
    )
  }
}

const mapStateToProps = (state) =>  ({
  alerts         : getAlerts(state),
  file           : getFileToDownload(state),
  userHasScopes  : userHasScopes(state),
  deelnemer      : getUserParty(state),
  profile        : getUserProfile(state),
  isAuthenticated: () => isAuthenticated(state),
  expiresAt      : getExpirationTime(state),
  progress       : getProgress(state),
})

export default connect(mapStateToProps, {
  removeAlert, removeFileForDownload, connectSocket,
  login,
  fetchProfile,
  addSessionTimer,
})(NavMenu)
