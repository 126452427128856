export const  FETCH_TRACKER_MESSAGES         = '[tracker] Fetch'
export const  STORE_TRACKER_MESSAGES         = '[tracker] Update store'

const url = '/rest/tracker/messages'

export const fetchMessages = dateFrom => ({
  type: FETCH_TRACKER_MESSAGES,
  payload: {
    url: url + (dateFrom ? '/' + dateFrom : ''),
  }
})

export const storeMessages = items => ({
  type: STORE_TRACKER_MESSAGES,
  payload: { items },
})
