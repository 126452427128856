/* global process */
import { version } from '../../package.json'
import { connect } from 'react-redux'
import {
  NavDropdown,
  Row, Col,
  Button,
} from 'react-bootstrap'
import React from 'react'

import PropTypes from 'prop-types'

import {
  getUserProfile,
  getUserParty,
  getUserSenders,
} from '../redux/selectors'
import {
  logout,
}  from '../redux/actions/auth'

/**
 * profile and logout are mandatory (see propTypes below)
 * thus do not render if either is not provided
 */

export function ProfileDropdown({ profile:p, deelnemer, senders, logout }) {
  const sha = process.env.REACT_APP_SHA ? '-'+process.env.REACT_APP_SHA.substring(0,8) : '' // eslint-disable-line
  const profile = p || { picture: 'https://placeholder.pics/svg/100/DEDEDE/DEDEDE-DEDEDE' }

  return (
    <NavDropdown id="profile-dropdown" title={<img src={profile.picture} alt="profile"/>}>
      <div className="navbar-login">
        <p className="text-center">
          <img src={profile.picture} alt="profile" height="90"/>
        </p>
        <table style={{ fontSize: 'smaller' }}>
          <tbody>
            <tr><td colSpan="2"><p><strong>{profile.email}</strong></p></td></tr>
            <tr><td><strong>Deelnemer&nbsp;</strong></td><td>{deelnemer}</td></tr>
            { senders &&
            <tr><td><strong>Stuur als</strong></td><td>{senders.join(', ')}</td></tr>
            }
            <tr><td colSpan="2">&nbsp;</td></tr>
            <tr><td><strong>Versie</strong></td><td>{version}{sha}</td></tr>
          </tbody>
        </table>
      </div>
      <div className="navbar-login navbar-login-session">
        <Row>
          <Col lg={12}>
            <Button id="logout" bsStyle="danger" onClick={logout}>Uitloggen</Button>
          </Col>
        </Row>
      </div>
    </NavDropdown>
  )
}

ProfileDropdown.propTypes = {
  /** profile of the user */
  profile: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    /** Url to a profile picture */
    picture: PropTypes.string,
  }),

  /** logout callback, for when the logout button is pressed */
  logout: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  profile: getUserProfile(state),
  deelnemer: getUserParty(state),
  senders: getUserSenders(state),
})

export default connect(mapStateToProps, {
  logout,
})(ProfileDropdown)
