import React, { Component }  from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  Button,
  FormControl,
  FormGroup,
  HelpBlock,
  InputGroup,
  Panel,
} from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import { MessageTable, MessageRow } from '../messages'

import {
  getSelectedOpdrachtStatus,
  getMessagesBySelectedOpdrachtStatus,
  getSelectedMessage,
  userHasScopes,
} from '../redux/selectors'
import { selectMessage } from '../redux/actions/page'
import { sendMessage } from '../redux/actions/messages'

export function VervolgBerichten(props) {
  return (
    <MessageTable>
      {props.messages.length > 0
        ?  props.messages.map(m => <MessageRow key={m._id} message={m}
          selected={props.selectedMessage && props.selectedMessage._id === m._id}
          onSelect={() => props.selectMessage(m._id)}/>)
        :  <tr><td colSpan="3">Geen vervolgberichten beschikbaar voor huidige selectie</td></tr>
      }
    </MessageTable>
  )
}

export class VervolgBerichtenPanel extends Component  {
  state = {
    file: '',
  }

  readFile = () => new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () =>  resolve(reader.result)
    reader.onabort = () => reject('file reading was aborted')
    reader.onerror = () => reject('file reading has failed')

    reader.readAsBinaryString(this.state.file)
  })

  onDrop = (acceptedFiles, rejectedFiles) => (acceptedFiles.length > 0) && this.setState({ file:acceptedFiles[0] })
  sendable = () => this.props.selectedMessage && this.props.opdrachtId
  send = (withAttachments) => {
    const props = this.props
    const { file } = this.state

    if (props.selectedMessage)  {
      props.selectMessage()

      if (file && file.name) {
        this.readFile()
          .then(xml => {
            props.sendMessage(props.selectedMessage, props.opdrachtId, withAttachments, xml)
          })
          .then(() => this.setState({ file:'' }))
      } else {
        props.sendMessage(props.selectedMessage, props.opdrachtId, withAttachments)
      }
    }
  }

  render() {
    const props = this.props
    const { file } = this.state
    const { userHasScopes } = this.props

    return (
      <Panel bsStyle="primary" defaultExpanded>
        <Panel.Heading>
          <Panel.Title toggle>
            <strong>Vervolgberichten</strong>
          </Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
          <VervolgBerichten {...props} />
          { userHasScopes(['create:additional_xml']) && props.messages.length > 0 &&
          <FormGroup controlId="message">
            <br/>
            <Dropzone onDrop={this.onDrop} multiple={false} name="message"
              accept="text/xml" className="form-control"
              style={{ height: '100%' }}
              activeClassName="primary" acceptClassName="success" rejectClassName="error" >

              <HelpBlock>Sleep hier 1 xml bericht naartoe</HelpBlock>
              {
                file.name &&
                      <div>Geselecteerd: <strong>{file.name}</strong></div>
              }
            </Dropzone>
            <FormControl.Feedback />
          </FormGroup>
          }
        </Panel.Collapse>
        <Panel.Footer>
          <HelpBlock> Druk verzenden om het geselecteerde vervolgbericht  te sturen naar DSP.</HelpBlock>
          <InputGroup>
            <InputGroup.Addon>Opdracht ID</InputGroup.Addon>
            <FormControl value={props.opdrachtId || ''} disabled={true} />
            <Button componentClass={InputGroup.Addon} bsStyle={ this.sendable() ? 'success' : 'default' } onClick={() => this.send(false)}
              disabled={!this.sendable()}
            >Verzenden</Button>
            <Button componentClass={InputGroup.Addon} bsStyle={ this.sendable() ? 'success' : 'default' } onClick={() => this.send(true)}
              disabled={!this.sendable()}
            >Verzenden met bijlagen</Button>
          </InputGroup>
        </Panel.Footer>
      </Panel>
    )

  }
}


VervolgBerichten.propTypes = {
  messages : PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }))
}

VervolgBerichtenPanel.propTypes = {
  opdrachtId : PropTypes.string,
  selectedMessage: PropTypes.shape({
    _id : PropTypes.string.isRequired,
  })
}

const opdrachtId = os =>os ? os.opdrachtId : null

const mapStateToProps = (state) =>  ({
  messages        : getMessagesBySelectedOpdrachtStatus(state),
  selectedMessage : getSelectedMessage(state),
  opdrachtId      : opdrachtId(getSelectedOpdrachtStatus(state)),
  userHasScopes   : userHasScopes(state),
})

export default connect(mapStateToProps, {
  selectMessage,
  sendMessage,
})(VervolgBerichtenPanel)
