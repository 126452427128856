import {
  Row, Col,
  ControlLabel,
  Panel,
} from 'react-bootstrap'
import React, { Fragment }  from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import PropTypes from 'prop-types'

import {  getDisciplineLabel } from '../redux/selectors'

const OPLEVERING_STATUS = 500

export function OpdrachtStatusDetail(props) {
  const wijzigingsDatum = props.changed ? moment(props.changed).format('D MMMM YYYY HH:mm') : ''

  return (
    <div>
      <Row><Col sm={6}>
        <Row><Col sm={4}><ControlLabel>Discipline</ControlLabel></Col>           <Col sm={8}>{props.disciplineLabel}</Col></Row>
        <Row><Col sm={4}><ControlLabel>Cluster ID</ControlLabel></Col>           <Col sm={8}>{props.clusterId}</Col></Row>
        <Row><Col sm={4}><ControlLabel>Versie</ControlLabel></Col>               <Col sm={8}>{props.versieNummer}</Col></Row>
        <Row><Col sm={4}><ControlLabel>Laatst gewijzigd op</ControlLabel></Col>  <Col sm={8}>{wijzigingsDatum}</Col></Row>
        <Row><Col sm={4}><ControlLabel>Netbeheerder</ControlLabel></Col>         <Col sm={8}>{props.netbeheerder}</Col></Row>
        <Row><Col sm={4}><ControlLabel>Aannemer</ControlLabel></Col>             <Col sm={8}>{props.aannemer}</Col></Row>
        { props.opdrachtnemer &&
          <Row><Col sm={4}><ControlLabel>Opdrachtnemer</ControlLabel></Col>        <Col sm={8}>{props.opdrachtnemer}</Col></Row>
        }
        <Row><Col sm={4}><ControlLabel>Actiehouder</ControlLabel></Col>          <Col sm={8}>{props.actiehouder}</Col></Row>
        <Row><Col sm={4}><ControlLabel>Proces</ControlLabel></Col>               <Col sm={8}>{props.proces}</Col></Row>
      </Col>

      <Col sm={6}>
        <Row><Col sm={3}><ControlLabel>Stadium</ControlLabel></Col>              <Col sm={9}>{props.stadium}</Col></Row>
        <Row><Col sm={3}><ControlLabel>Status</ControlLabel></Col>               <Col sm={9}>{props.statusText}</Col></Row>
        <Row><Col sm={3}><ControlLabel>Beschrijving</ControlLabel></Col>         <Col sm={9}>{props.beschrijving}</Col></Row>
        { props.status === OPLEVERING_STATUS  &&
          <Fragment>
            <Row><Col sm={3}><ControlLabel>Status TG</ControlLabel></Col>        <Col sm={9}>{props.statusTextTG}</Col></Row>
            <Row><Col sm={3}><ControlLabel>Status AGA</ControlLabel></Col>       <Col sm={9}>{props.statusTextAGA}</Col></Row>
            <Row><Col sm={3}><ControlLabel>Status AGP</ControlLabel></Col>       <Col sm={9}>{props.statusTextAGP}</Col></Row>
          </Fragment>
        }
      </Col></Row>

    </div>
  )
}

OpdrachtStatusDetail.propTypes = {
  opdrachtId  : PropTypes.string.isRequired,
  stadium     : PropTypes.string.isRequired,
  statusText  : PropTypes.string.isRequired,
  beschrijving: PropTypes.string.isRequired,
  changed     : PropTypes.string.isRequired,
  netbeheerder: PropTypes.string.isRequired,
  aannemer    : PropTypes.string.isRequired,
  actiehouder : PropTypes.string.isRequired,
}

function TogglePanel({ opdrachtId, children }) {
  return (
    <Panel bsStyle="primary" defaultExpanded>
      <Panel.Heading>
        <Panel.Title toggle>
          <strong>{opdrachtId}</strong>
        </Panel.Title>
      </Panel.Heading>
      <Panel.Collapse>
        <Panel.Body>
          {children}
        </Panel.Body>
      </Panel.Collapse>
    </Panel>
  )
}

export function OpdrachtStatusDetailPanel(props) {
  return (
    <TogglePanel {...props}>
      <OpdrachtStatusDetail {...props} />
    </TogglePanel>
  )
}



const mapStateToProps = () => (state, ownProps) => ({
  disciplineLabel : getDisciplineLabel(state, ownProps.discipline),
})

export default connect(mapStateToProps)(OpdrachtStatusDetailPanel)
