export const SELECT_VERSION         = '[page] Select version'
export const SELECT_TEMPLATE        = '[page] Select template'
export const SELECT_MESSAGE         = '[page] Select message'
export const SELECT_CERTIFICATE     = '[page] Select certificate'
export const SELECT_OPDRACHT_STATUS = '[page] Select opdracht status'
export const SELECT_PARTY           = '[page] Select party'
export const SELECT_OPDRACHTNEMER   = '[page] Select opdrachtnemer'
export const ADD_DOWNLOAD_FILE      = '[page] Add download file'
export const REMOVE_DOWNLOAD_FILE   = '[page] Remove download file'
export const SET_ADV_SEARCH         = '[page] Set uitgebreid zoeken'

export const selectTemplate = templateId => ({
  type: SELECT_TEMPLATE,
  payload: { templateId },
})

export const selectMessage = messageId => ({
  type: SELECT_MESSAGE,
  payload: { messageId },
})

export const selectVersion = version => ({
  type: SELECT_VERSION,
  payload: { version },
})

export const selectOpdrachtStatus = id => ({
  type: SELECT_OPDRACHT_STATUS,
  payload: { id },
})

export const selectCertificate = certificateId => ({
  type: SELECT_CERTIFICATE,
  payload: { certificateId },
})

export const selectParty = party => ({
  type: SELECT_PARTY,
  payload: { party },
})

export const selectOpdrachtnemer = opdrachtnemer => ({
  type: SELECT_OPDRACHTNEMER,
  payload: { opdrachtnemer }
})

export const storeFileForDownload = (filename, content) => ({
  type: ADD_DOWNLOAD_FILE,
  payload: { content, filename }
})

export const removeFileForDownload = () => ({
  type: REMOVE_DOWNLOAD_FILE,
  payload: {}
})

export const setAdvSearch = advSearch => ({
  type: SET_ADV_SEARCH,
  payload: { advSearch }
})

