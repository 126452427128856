import {
  SEARCH_WERKBAKKEN,
  storeWerkbakken,
} from '../actions/werkbakken'
import {
  API_SUCCESS, API_ERROR,
} from '../actions/api'

import { apiRequest } from '../actions/api'
import { apiError, clearAlerts } from '../actions/alerts'

export const searchWerkbakFlow = ({ dispatch }) => next => action => {
  next(action)

  if (action.type === SEARCH_WERKBAKKEN) {
    const { url, query:body } = action.payload

    dispatch(apiRequest({ method: 'POST', url, body, feature: SEARCH_WERKBAKKEN }))
  }

  if (action.type === `${SEARCH_WERKBAKKEN} ${API_SUCCESS}`) {
    dispatch(storeWerkbakken(action.payload))
  }

  if (action.type === `${SEARCH_WERKBAKKEN} ${API_ERROR}`) {
    dispatch(clearAlerts())
    dispatch(apiError('Fout bij lezen van werkbakken', action.payload))
  }
}

export const werkbakMiddleware = [searchWerkbakFlow]
