import { Grid } from 'react-bootstrap'
import { connect } from 'react-redux'
import React from 'react'

import PropTypes from 'prop-types'

import { MessageForm, MessageTable, MessageRow } from '.'
import {
  createMessage,
  deleteMessage,
  updateMessage,
  downloadXml,
} from '../redux/actions/messages'
import {
  getMessagesBySelectedTemplate,
  getSelectedMessage,
  getSelectedTemplate,
  getMessageById,
  getDisciplines,
} from '../redux/selectors'
import { info } from '../redux/actions/alerts'
import {
  selectMessage,
} from '../redux/actions/page'

export class MessageMaintenance extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      currentMessage: null,
      messages: [],
      showModal: false,
    }
  }

  onCreate = () => {
    if (this.props.selectedTemplate) {
      this.setState({ currentMessage: {
        _id: undefined,
        description: this.props.selectedTemplate.description,
        xml:'',
        template: this.props.selectedTemplate._id
      }, showModal: true })
    } else {
      this.props.info('Selecteer een template waarvoor het bericht moet worden aangemaakt.')
    }
  }

  onEdit = id => {
    const currentMessage = { ...this.props.getMessageById(id) }

    this.setState({ currentMessage, showModal: true })
  }

  onSubmit = (message) => {
    this.setState({ showModal: false })

    if (message._id) {
      this.props.updateMessage(message)
    } else {
      this.props.createMessage(message)
    }
  }

  renderMessageRows = () =>
    this.props.messages
      .map(message =>
        <MessageRow key={message._id} message={message}
          selected={this.props.selectedMessage && this.props.selectedMessage._id === message._id}
          editMode={true}
          onEdit={this.onEdit}
          onDelete={this.props.deleteMessage}
          onSelect={this.props.selectMessage}
          downloadXml={this.props.downloadXml}
        />
      )


  render() {
    return (
      <Grid>
        <MessageTable onCreate={this.onCreate} editmode={true}>
          {this.renderMessageRows()}
        </MessageTable>
        <MessageForm show={this.state.showModal}
          disciplines={this.props.disciplines}
          message={this.state.currentMessage}
          onSubmit={this.onSubmit} onClose={() => this.setState({ showModal: false })}/>
      </Grid>
    )
  }
}

MessageMaintenance.propTypes = {
  selectMessage: PropTypes.func.isRequired,
  getMessageById: PropTypes.func,
  deleteMessage: PropTypes.func,
  selectedTemplate: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }),
  selectedMessage: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  })
}

MessageMaintenance.defaultProps = {
  messages: [],
}

const mapStateToProps = state => ({
  messages         : getMessagesBySelectedTemplate(state),
  selectedMessage  : getSelectedMessage(state),
  selectedTemplate : getSelectedTemplate(state),
  getMessageById   : id => getMessageById(state, id),
  disciplines      : getDisciplines(state),
})

export default connect(mapStateToProps, {
  selectMessage, createMessage, updateMessage, deleteMessage,
  info, downloadXml,
})(MessageMaintenance)
