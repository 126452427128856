import {
  STORE_VALUELISTS,
} from '../actions/valuelists'

// waarde lijstje met alle disciplines
const disciplines =  {
  '-': 'Alle',
  'C': 'CAI',
  'E': 'Elektra',
  'G': 'Gas',
  'M': 'Glas',
  'K': 'Koper',
  'W': 'Water',
  'R':'Riool',
}

const toDict = arr => {
  const dict = { '-': 'Alle' }

  if (Array.isArray(arr))
    return arr.sort().reduce((dict, entry) => {
      dict[entry] = disciplines[entry] || entry

      return dict
    }, dict)

  return dict
}

const initialState = {
  disciplines,
  discipline:  toDict(), // disciplines van deelnemer
  aannemer:    toDict(), // aannemers behorende bij deelnemer
  netbeheerder:toDict(), // netbeheerders behorende bij deelnemer
  versieNummer:toDict(), // versienummer behorende bij deelnemer
}


export default function diciplines(state = initialState, action) {
  switch (action.type) {

  case STORE_VALUELISTS:

    const newState =  Object.entries(action.payload.items)
      .reduce((state, [k,v]) => {
        if (k !== 'disciplines') // plural
          state[k] = toDict(v)

        return state
      } ,{})

    return { ...initialState, ...newState }

  default:
    return state
  }
}

/** Get discipline dictionary */
export const getDisciplines      = state => ({ ...state.valuelists.disciplines  })
export const getNetbeheerders    = state => ({ ...state.valuelists.netbeheerder })
export const getAannemers        = state => ({ ...state.valuelists.aannemer     })
export const getVersieNummers    = state => ({ ...state.valuelists.versieNummer })
export const getDisciplineByRole = state => ({ ...state.valuelists.discipline    })

/** Get label for discipine key */
export const getDisciplineLabel = (state, key) => getDisciplines(state)[key]

