import {
  Button,
  FormControl,
  FormGroup,
  HelpBlock,
  InputGroup,
  Panel,
} from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import { connect } from 'react-redux'
import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { MessageTable, MessageRow } from '../messages'
import {
  getOpdrachtMessages,
  getSelectedMessage,
  userHasScopes,
} from '../redux/selectors'
import { selectMessage } from '../redux/actions/page'
import { sendMessage } from '../redux/actions/messages'

const MIN_LENGTH_OPDRACHT_ID = 5

export class NieuweOpdrachtView extends Component {

  state = { opdrachtId: '', file: '' }

  readFile = () => new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () =>  resolve(reader.result)
    reader.onabort = () => reject('file reading was aborted')
    reader.onerror = () => reject('file reading has failed')

    reader.readAsBinaryString(this.state.file)
  })

  onDrop = (acceptedFiles, rejectedFiles) => (acceptedFiles.length > 0) && this.setState({ file:acceptedFiles[0] })

  handleChange = event => this.setState({ opdrachtId: event.target.value })

   validate = () => {
     const length = this.state.opdrachtId.length

     if (length < MIN_LENGTH_OPDRACHT_ID || !this.props.selectedMessage) {
       return this.state.submitted ? 'error' : null
     }

     return 'success'
   }

  sendOpdrachtBericht = (withAttachments) => {
    const props = this.props
    const { file } = this.state

    if (!props.selectedMessage || this.state.opdrachtId.length < MIN_LENGTH_OPDRACHT_ID)  {
      return this.setState({ submitted: true })
    }
    props.selectMessage()

    if (file && file.name) {
      this.readFile()
        .then(xml => {
          props.sendMessage(props.selectedMessage, this.state.opdrachtId, withAttachments, xml)
        })
        .then(() => this.setState({ submitted: false, file:'' }))
    } else {
      props.sendMessage(props.selectedMessage, this.state.opdrachtId, withAttachments)
      this.setState({ submitted: false })
    }
  }

  render() {
    const props = this.props
    const { file } = this.state

    return (
      <Panel bsStyle="primary">
        <Panel.Heading>
          <Panel.Title>
            <strong>Opdrachtberichten</strong>
          </Panel.Title>
        </Panel.Heading>
        <MessageTable>
          {props.messages.length > 0
            ?  props.messages.map(m => <MessageRow key={m._id} message={m}
              selected={props.selectedMessage && props.selectedMessage._id === m._id}
              onSelect={props.selectMessage}/>)
            :  <tr><td colSpan="3">Geen opdrachtberichten beschikbaar</td></tr>
          }
        </MessageTable>
        { props.userHasScopes(['create:additional_xml']) &&
          <FormGroup controlId="message">
            <br/>
            <Dropzone onDrop={this.onDrop} multiple={false} name="message"
              accept="text/xml" className="form-control"
              style={{ height: '100%' }}
              activeClassName="primary" acceptClassName="success" rejectClassName="error" >

              <HelpBlock>Sleep hier 1 xml bericht naartoe</HelpBlock>
              {
                file.name &&
                      <div>Geselecteerd: <strong>{file.name}</strong></div>
              }
            </Dropzone>
            <FormControl.Feedback />
          </FormGroup>
        }
        <Panel.Footer>
          <HelpBlock>
              Druk verzenden om het geselecteerde opdrachtbericht te sturen naar DSP.<br/>
              Onderstaand opdracht ID zal hierbij worden gebruikt.
          </HelpBlock>
          <FormGroup controlId="opdrachtId" validationState={this.validate()} >
            <InputGroup>
              <InputGroup.Addon>Opdracht ID</InputGroup.Addon>
              <FormControl value={this.state.opdrachtId} onChange={this.handleChange} />
              <Button componentClass={InputGroup.Addon} bsStyle="success" onClick={() => this.sendOpdrachtBericht(false)}
              >Verzenden</Button>
              <Button componentClass={InputGroup.Addon} bsStyle="success" onClick={() => this.sendOpdrachtBericht(true)}
              >Verzenden met bijlagen</Button>
            </InputGroup>
            { 'error' === this.validate()  &&
                <HelpBlock>Selecteer een opdrachtbericht en type een opdracht ID van minimaal {MIN_LENGTH_OPDRACHT_ID} tekens</HelpBlock>
            }
          </FormGroup>
        </Panel.Footer>
      </Panel>
    )
  }
}

NieuweOpdrachtView.propTypes = {
  messages        : PropTypes.array.isRequired,
  selectMessage   : PropTypes.func.isRequired,
  sendMessage     : PropTypes.func.isRequired,
  selectedMessage : PropTypes.shape({
    _id           : PropTypes.string.isRequired,
  })
}


const mapStateToProps = (state) =>  ({
  messages               : getOpdrachtMessages(state),
  selectedMessage        : getSelectedMessage(state),
  userHasScopes          : userHasScopes(state),
})

export default connect(mapStateToProps, {
  selectMessage,
  sendMessage,
})(NieuweOpdrachtView)
