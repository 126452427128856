import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import React from 'react'

import PropTypes from 'prop-types'

import { formatDateTime } from '../util'
import { makeGetMessageCountByTemplateId } from '../redux/selectors'

export function TemplateRow({ template, selected, onEdit, onCopy, onSelect, messageCount }) {
  return(
    <tr onClick={() => onSelect(template._id)} className={selected ? 'selected' : ''}>
      <td>{template.description}</td>
      <td>{template.sender}</td>
      <td>{template.status.join(', ')}</td>
      <td>{template.version}</td>
      <td>{formatDateTime(template.created)}</td>
      <td>{formatDateTime(template.updated)}</td>
      <td>{messageCount}</td>

      <td align="center" style={{ width: '32px' }}>
        <a href={`templates/edit(${template._id})`}title="Kopieren">
          <FontAwesomeIcon icon="copy" onClick={e => {e.preventDefault(); onCopy(template._id)}} />
        </a>
      </td>

      <td align="center" style={{ width: '32px' }}>
        <a href={`templates/edit(${template._id})`} title="Bewerken">
          <FontAwesomeIcon icon="edit" onClick={e => {e.preventDefault(); onEdit(template._id)}} />
        </a>
      </td>
    </tr>
  )
}

TemplateRow.propTypes = {
  selected     : PropTypes.bool,
  onEdit       : PropTypes.func.isRequired,
  onCopy       : PropTypes.func.isRequired,
  onSelect     : PropTypes.func.isRequired,
  messageCount : PropTypes.number,
  template     : PropTypes.shape({
    _id          : PropTypes.string.isRequired,
    status       : PropTypes.array.isRequired,
    description  : PropTypes.string,
    sender       : PropTypes.string,
    version      : PropTypes.string,
    created      : PropTypes.isRequired,
    updated      : PropTypes.isRequired,
  }),
}

TemplateRow.defaultProps = {
  selected     : false,
  messageCount : 0,
}

const mapStateToProps = () =>{
  const getMessageCount = makeGetMessageCountByTemplateId()

  return (state, ownProps) => {
    return { messageCount:getMessageCount(state, ownProps) }
  }
}

export default connect(mapStateToProps)(TemplateRow)
