import './tablescroll.css'

import {
  Panel,
  Row, Col,
} from 'react-bootstrap'
import React from 'react'
import moment from 'moment'

import PropTypes from 'prop-types'

const opdrCompare = (a, b) =>  moment(a.changed).isBefore(b.changed) ? 1 : moment(a.changed).isAfter(b.changed) ? -1 : 0


export class OpdrachtStatusList extends React.Component {

  render() {
    const props = this.props

    return (
      <Panel bsStyle="primary" defaultExpanded>
        <Panel.Heading>
          <Panel.Title toggle>
            <strong>{props.title}</strong>
          </Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
          <div className="scroll-header">
            <Row>
              <Col xs={3} sm={3} md={2} lg={2} xl={2}>Opdracht ID</Col>
              <Col xs={3} sm={4} md={5} lg={6} xl={6}>Status beschrijving</Col>
              <Col xs={2} sm={2} md={2} lg={2} xl={2}>Versie</Col>
              <Col xs={4} sm={3} md={3} lg={2} xl={2}>Gewijzigd</Col>
            </Row>
          </div>
          <div className="scroll-container">

            { props.list.sort(opdrCompare).map(this.renderItem) }

          </div>
        </Panel.Collapse>
        <Panel.Footer>
          {`${props.list.length} ${props.list.length === 1 ? 'resultaat' : 'resultaten'} gevonden.`}
        </Panel.Footer>
      </Panel>
    )
  }

  renderItem = os => {
    const props = this.props
    const wijzigingsDatum = os.changed ? moment(os.changed).format('YYYY-MM-DD HH:mm') : ''

    const rowProps = {
      onClick: () => props.selectOpdrachtStatus(os._id)
    }

    if (os === props.selected) {
      rowProps['className'] = 'selected'
    }

    return (
      <div  key={os._id} {...rowProps}>
        <Row>
          <Col xs={3} sm={3} md={2} lg={2} xl={2} style={{ wordWrap: 'break-word' }}>{os.opdrachtId}</Col>
          <Col xs={3} sm={4} md={5} lg={6} xl={6}>{os.beschrijving}</Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2}>{os.versieNummer}</Col>
          <Col xs={4} sm={3} md={3} lg={2} xl={2}>{wijzigingsDatum}</Col>
        </Row>
      </div>
    )}

}

const OpdrachtStatusType = PropTypes.shape({
  opdrachtId   : PropTypes.string.isRequired,
  statusText   : PropTypes.string.isRequired,
  beschrijving : PropTypes.string.isRequired,
})

OpdrachtStatusList.propTypes = {
  list                 : PropTypes.arrayOf(OpdrachtStatusType),
  selected             : OpdrachtStatusType,
  selectOpdrachtStatus : PropTypes.func.isRequired,
  title                : PropTypes.string,
}

OpdrachtStatusList.defaultProps = {
  selectedId : '',
  title              : 'Zoek Resultaten',
}
export default OpdrachtStatusList
