import {
  FETCH_TRACKER_MESSAGES,
  storeMessages,
} from '../actions/tracker'
import {
  API_SUCCESS, API_ERROR,
} from '../actions/api'
import {
  apiError,
  clearAlerts,
} from '../actions/alerts'
import { apiRequest } from '../actions/api'


export const fetchMessagesFlow = ({ dispatch }) => next => action => {
  next(action)

  if (action.type === FETCH_TRACKER_MESSAGES) {
    dispatch(apiRequest({ method:'GET', url:action.payload.url, feature:FETCH_TRACKER_MESSAGES }))
  }

  if (action.type === `${FETCH_TRACKER_MESSAGES} ${API_SUCCESS}`) {
    dispatch(storeMessages(action.payload))
  }

  if (action.type === `${FETCH_TRACKER_MESSAGES} ${API_ERROR}`) {
    dispatch(clearAlerts())
    dispatch(apiError('Fout bij lezen van tracker gegevens', action.payload))
  }
}


export const trackerMiddleware = [fetchMessagesFlow]
