import { createSelector } from 'reselect'

import {
  STORE_PROFILE,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_LOGOUT,
} from '../actions/auth'

function saveSession(authResult) {
  let millis = 1000
  let expiresAt = JSON.stringify((authResult.expiresIn * millis) + new Date().getTime())

  localStorage.setItem('access_token', authResult.accessToken)
  localStorage.setItem('id_token', authResult.idToken)
  localStorage.setItem('expires_at', expiresAt)
  localStorage.setItem('scopes', JSON.stringify(authResult.scope))
}

function removeSession() {
  localStorage.removeItem('access_token')
  localStorage.removeItem('id_token')
  localStorage.removeItem('expires_at')
  localStorage.removeItem('scopes')
}

function loadState() {
  const accessToken = localStorage.getItem('access_token')
  const expiresAt = JSON.parse(localStorage.getItem('expires_at'))
  const scopes = (JSON.parse(localStorage.getItem('scopes')) || '').split(' ')

  return { accessToken, expiresAt, scopes }
}


export default function authReducer(state, action) {

  switch (action.type) {
  case AUTH_LOGIN_SUCCESS:
    saveSession(action.payload.authResult)

    return loadState()

  case AUTH_LOGOUT:
  case AUTH_LOGIN_FAILURE: // intentional fall through
    removeSession()

    return {}

  case STORE_PROFILE:
    return { ...state, profile: action.payload.profile }

  default:
    if (!state) {
      return loadState()
    }

    return state
  }
}

const profile = state => state.auth.profile

export const getAccessToken = state => state.auth.accessToken

export const getUserScopes = state => state.auth.scopes

export const getExpirationTime = state => state.auth.expiresAt

export const getUserProfile = createSelector(
  profile,
  profile => profile && { ...profile }
)

export const getUserRoles = createSelector(
  profile,
  profile => profile && profile['http://analytics.dsplatform.nl/roles']
)

export const getUserParty = createSelector(
  profile,
  profile => profile && profile['http://analytics.dsplatform.nl/deelnemer']
)

export const getUserHasLip = createSelector(
  profile,
  profile => profile && profile['http://analytics.dsplatform.nl/has_lip']
)

export const isAuthenticated = state => state.auth.expiresAt && new Date().getTime() < state.auth.expiresAt

export const userHasScopes = state => scopes => {
  const grantedScopes = getUserScopes(state)

  return isAuthenticated(state) && scopes.every(scope => grantedScopes.includes(scope))
}

export const getUserSenders = state =>
  getUserScopes(state)
    .filter(s => s.indexOf('sender') > -1)
    .map(s => s.replace(/^read:sender_/, ''))

