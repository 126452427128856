import { createSelector } from 'reselect'
import {
  STORE_TEMPLATE,
  STORE_TEMPLATES,
} from '../actions/templates'

import { messageComparator } from '../../util'

const initialState = {
  items: {},
}

export default function templatesReducer(state = initialState, action) {

  switch (action.type) {
  case STORE_TEMPLATES:
    const items = action.payload.items.reduce((acc, t) => {
      acc[t._id] = t

      return acc
    }, {})

    return { ...state, items }

  case STORE_TEMPLATE:
    const { template } = action.payload

    return { ...state, items: { ...state.items, [template._id]: template } }

  default:
    return state
  }
}

/** get direct reference to templates in store */
const templates = state => state.templates.items

/** Get an array of templates.  */
export const getTemplates = createSelector(
  templates,
  templates => Object.values(templates)
)

/** Get a template for the given id. Undefined if unknown.  */
export const getTemplateById = (state, id) => templates(state)[id]

/**
 * Get an array of versions constructed from the templates in the state.
 * The array is sorted in lexicographical order.
 */
export const getVersions = createSelector(
  getTemplates,
  templates => [...new Set(templates.map(t=>t.version))].sort()
)

/** Get an sorted array of templates */
export const getSortedTemplates = createSelector(
  getTemplates,
  templates => templates.sort(messageComparator)
)

/** Check if template for id is an opdracht */
export const isOpdrachtTemplate = (state, id) => {
  const template = getTemplateById(state, id)

  if (!template) return false

  const result = template.status.indexOf(0) >= 0

  return result
}

/** filter function to match a template with a status */
export const filterTemplateByStatus = status => t => t.status.indexOf(status) >= 0

/** filter function to match a template with a version */
export const filterTemplateByVersion = version => t => t.version === version


