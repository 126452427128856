import PropTypes from 'prop-types'

export function FileDownload(props) {
  if (props.filename && props.content) {
    const link = document.createElement('a')

    link.href = URL.createObjectURL(new Blob([props.content]), { type: 'text/xml' })
    link.download = props.filename
    link.click()

    if (props.done) props.done()
  } else {
    console.warn('FileDownload component mounted but nothing to do') // eslint-disable-line
  }

  return false
}

FileDownload.propTypes = {
  filename: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  done: PropTypes.func,
}

export default FileDownload
