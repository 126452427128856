export const FETCH_VALUELISTS   = '[valuelists] Fetch'
export const STORE_VALUELISTS   = '[valuelists] Update store'


const url = '/rest/valuelists'

export const fetchValuelists = () => ({
  type: FETCH_VALUELISTS,
  payload: { url }
})

export const storeValuelists = items => ({
  type: STORE_VALUELISTS,
  payload: { items }
})

