import {
  UPDATE_PROGRESS,
  CLEAR_PROGRESS,
} from '../actions/progress'


export default function progresbarReducer(state = {}, action) {
  switch (action.type) {
  case CLEAR_PROGRESS:
    return {}
  case UPDATE_PROGRESS:
    const { now, max } = action.payload

    return { now, max, text: action.payload.text || state.text }
  default:
    return state
  }
}
