export const API_REQUEST = 'API request'
export const API_SUCCESS = 'API success'
export const API_ERROR = 'API error'

export const apiRequest = ({ method, url, body, feature, props }) => {
  const action = {
    type: `${feature} ${API_REQUEST}`,
    payload: body,
    meta: { method, url, feature },
  }

  if(props)
    action.meta.props = props

  return action
}

export const apiSuccess = ({ response, feature, props }) => {
  const action = {
    type: `${feature} ${API_SUCCESS}`,
    payload: response,
    meta: { feature },
  }

  if(props)
    action.meta.props = props

  return action
}

export const apiError = ({ error, feature }) => ({
  type: `${feature} ${API_ERROR}`,
  payload: error,
  meta: { feature },
})
