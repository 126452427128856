export const FETCH_RULES          = '[rules] Fetch'
export const STORE_RULES          = '[rules] Update store'
export const STORE_CLEAR_RULES    = '[rules] Clear store'
export const CREATE_RULE          = '[rule] Create'
export const UPDATE_RULE          = '[rule] Update'
export const DELETE_RULE          = '[rule] Delete'
export const STORE_RULE           = '[rule] Update store'
export const STORE_REMOVE_RULE    = '[rule] Remove from store'
export const UNDO_REMOVE_RULE     = '[rule] Undo Remove from store'
export const ACTIVATE_RULES       = '[rules] Activate'
export const VALIDATE_RULES       = '[rules] Validate'
export const RESET_RULES          = '[rules] Reset'
export const EXPORT_RULES         = '[rules] Export'
export const IMPORT_RULES         = '[rules] Import'
export const FETCH_OPDRACHTNEMERS = '[opdrachtnemers] Fetch'
export const STORE_OPDRACHTNEMERS = '[opdrachtnemers] Update store'

const url = '/rest/swv/rules'

export const fetchActiveRules = () => ({
  type: FETCH_RULES,
  payload: {
    url: url,
    active: true
  }
})

export const fetchDraftRules = () => ({
  type: FETCH_RULES,
  payload: {
    url: url,
    active: false
  }
})

export const storeRules = items => ({
  type: STORE_RULES,
  payload: { items },
})

export const clearRules = () => ({
  type: STORE_CLEAR_RULES,
  payload: { },
})

export const createRule = rule => ({
  type: CREATE_RULE,
  payload: {
    url,
    rule,
  }
})

export const updateRule = rule => ({
  type: UPDATE_RULE,
  payload: {
    url: `${url}/${rule._id}`,
    rule,
  }
})

export const deleteRule = id => ({
  type: DELETE_RULE,
  payload: {
    url: `${url}/${id}`,
    id,
  }
})

export const storeRule = rule => ({
  type: STORE_RULE,
  payload: { rule }
})

export const storeRemoveRule = id => ({
  type: STORE_REMOVE_RULE,
  payload: { id }
})

export const undoStoreRemoveRule = () => ({
  type: UNDO_REMOVE_RULE,
  payload: { }
})

export const activateRules = () => ({
  type: ACTIVATE_RULES,
  payload: {
    url: `${url}/activate`
  }
})

export const validateRules = () => ({
  type: VALIDATE_RULES,
  payload: {
    url: `${url}/validate`
  }
})

export const resetRules = () => ({
  type: RESET_RULES,
  payload: {
    url: `${url}/reset`
  }
})

export const exportRules = (active) => ({
  type: EXPORT_RULES,
  payload: {
    url: `${url}/csv`,
    active
  }
})

export const importRules = (rules) => ({
  type: IMPORT_RULES,
  payload: {
    url: `${url}/import`,
    rules
  }
})

export const fetchOpdrachtnemers = () => ({
  type: FETCH_OPDRACHTNEMERS,
  payload: {
    url: `${url}/opdrachtnemers`,
  }
})

export const storeOpdrachtnemers = items => ({
  type: STORE_OPDRACHTNEMERS,
  payload: { items },
})
