import {
  STORE_MESSAGE,
  STORE_MESSAGES,
  STORE_REMOVE_MESSAGE,
  UNDO_REMOVE_MESSAGE,
} from '../actions/messages'

import { createSelector } from 'reselect'

const initialState = {
  items: {},
  removed: [],
}


export default function messagesReducer(state = initialState, action) {

  switch (action.type) {
  case STORE_MESSAGES:
    const items = action.payload.items.reduce((acc, t) => {
      acc[t._id] = t

      return acc
    }, {})

    return { ...state, items }

  case STORE_MESSAGE:
    const message = (action.payload && action.payload.message)

    return { ...state, items: { ...state.items, [message._id]: message } }

  case STORE_REMOVE_MESSAGE:
    const { [action.payload.id]:removedMessage, ...newItems } = state.items

    return { ...state, items:newItems, removed: [...state.removed, removedMessage] }

  case UNDO_REMOVE_MESSAGE:
    if (state.removed.length === 0)
      return state

    const removed = state.removed.slice(0)
    const undo = removed.pop()

    return { ...state, items: { ...state.items, [undo._id]: undo }, removed }

  default:
    return state
  }
}



/** get direct reference to messages in store */
const messages  = state => state.messages.items

/** Get an array of messages. */
export const getMessages = createSelector(
  messages,
  messages => Object.values(messages)
)

/** Get a message for the given id. Undefined if unknown.  */
export const getMessageById = (state, id) => messages(state)[id]

/** Get messages for a template id */
export const getMessagesByTemplateId = (messages, templateId) =>
  messages.filter(m => m.template === templateId)

/** Get message count for a template id */
export const getMessageCountByTemplateId = (messages, templateId) =>
  getMessagesByTemplateId(messages, templateId).length

/** Get an array of message filtered by given templateIds */
export const filterMessageByTemplateIds = templateIds => m => templateIds.includes(m.template)

/** Get an array of message filtered by given discipline */
export const filterMessageByDiscipline = discipline => m => m.discipline === discipline

/** Get filename */
export const formatMessageFilename = (message, disciplineLabel) => {
  if (!message) return null

  const label = disciplineLabel ? disciplineLabel : message.discipline

  return `${message.description} - ${label}.xml`
}
