import {
  SAVE_ENDPOINT,
  FETCH_ENDPOINTS,
  FETCH_PARTIES,
  storeEndpoint, storeEndpoints,
  storeParties,
} from '../actions/endpoints'
import {
  getEndpointsBySystem
} from '../reducers/endpoints'
import {
  API_SUCCESS, API_ERROR,
} from '../actions/api'
import {
  apiError,
  clearAlerts,
  info,
} from '../actions/alerts'
import { apiRequest } from '../actions/api'

export const fetchEndpointsFlow = ({ dispatch }) => next => action => {
  next(action)

  if (action.type === FETCH_ENDPOINTS) {
    dispatch(apiRequest({ method:'GET', url:action.payload.url, feature:FETCH_ENDPOINTS }))
  }

  if (action.type === `${FETCH_ENDPOINTS} ${API_SUCCESS}`) {
    dispatch(storeEndpoints(action.payload))
  }

  if (action.type === `${FETCH_ENDPOINTS} ${API_ERROR}`) {
    dispatch(clearAlerts())
    dispatch(apiError('Fout bij lezen van endpoints', action.payload))
  }
}

export const saveEndpointFlow = ({ dispatch, getState }) => next => action => {
  next(action)

  if (action.type === SAVE_ENDPOINT) {

    const endpointList = { ...getEndpointsBySystem(getState(), action.payload.system), endpoints:action.payload.endpoints }
    const method = endpointList &&  endpointList._id ? 'PUT' : 'POST'


    const body = {
      endpoints: action.payload.endpoint
    }

    dispatch(apiRequest({ method, url:action.payload.url, body, feature: SAVE_ENDPOINT }))
    dispatch(info('Endpoints worden opgeslagen'))
  }

  if (action.type === `${SAVE_ENDPOINT} ${API_SUCCESS}`) {
    dispatch(storeEndpoint(action.payload))
    dispatch(clearAlerts())
  }

  if (action.type === `${SAVE_ENDPOINT} ${API_ERROR}`) {
    dispatch(clearAlerts())
    dispatch(apiError('Fout bij opslaan van endpoints', action.payload))
  }
}

export const fetchPartiesFlow = ({ dispatch }) => next => action => {
  next(action)

  if (action.type === FETCH_PARTIES) {
    dispatch(apiRequest({ method:'GET', url:action.payload.url, feature:FETCH_PARTIES }))
  }

  if (action.type === `${FETCH_PARTIES} ${API_SUCCESS}`) {
    dispatch(storeParties(action.payload))
  }

  if (action.type === `${FETCH_PARTIES} ${API_ERROR}`) {
    dispatch(clearAlerts())
    dispatch(apiError('Fout bij lezen van parties', action.payload))
  }
}

export const endpointMiddleware = [fetchEndpointsFlow, saveEndpointFlow, fetchPartiesFlow]
