export const STORE_OPDRACHT_STATUS        = '[opdracht status] Update store'
export const STORE_CLEAR_OPDRACHT_STATUS  = '[opdracht status] Clear store'
export const QUERY_OPDRACHT_STATUS        = '[opdracht status] Query'
export const SEARCH_OPDRACHT_STATUS       = '[opdracht status] Search'
export const FETCH_OPDRACHT_STATUS        = '[opdracht status] Fetch'

export const DOWNLOAD_QUERY_OPDRACHT      = '[opdracht status] Download query'

const url = '/rest/opdracht'

export const fetchOpdrachtStatus = () => ({
  type: FETCH_OPDRACHT_STATUS,
  payload: {
    url
  }
})

export const queryOpdrachtStatus = (query) => ({
  type: QUERY_OPDRACHT_STATUS,
  payload: {
    url,
    query,
  }
})

export const searchOpdrachtStatus = (query) => ({
  type: SEARCH_OPDRACHT_STATUS,
  payload: {
    url,
    query,
  }
})

export const storeOpdrachtStatus = items => ({
  type: STORE_OPDRACHT_STATUS,
  payload: { items },
})

export const clearOpdrachtStatus = () => ({
  type: STORE_CLEAR_OPDRACHT_STATUS,
  payload: { },
})

export const downloadOpdrachtQuery = (filters, filename) => ({
  type: DOWNLOAD_QUERY_OPDRACHT,
  payload: {
    url: url + '/csv',
    filters,
    filename,
  }
})
