import {
  FETCH_OPDRACHT_STATUS,
  QUERY_OPDRACHT_STATUS,
  SEARCH_OPDRACHT_STATUS,
  storeOpdrachtStatus,
} from '../actions/opdrachtstatus'

import { API_SUCCESS, API_ERROR, apiRequest } from '../actions/api'
import { apiError, clearAlerts, info }        from '../actions/alerts'
import { selectOpdrachtStatus }               from '../actions/page'

export const fetchOpdrachtStatusFlow = ({  dispatch }) => next => action => {
  next(action)

  if (action.type === FETCH_OPDRACHT_STATUS) {
    dispatch(apiRequest({ method: 'GET', url:action.payload.url, feature: FETCH_OPDRACHT_STATUS }))
  }

  if (action.type === `${FETCH_OPDRACHT_STATUS} ${API_SUCCESS}`) {
    dispatch(storeOpdrachtStatus(action.payload))
  }

  if (action.type === `${FETCH_OPDRACHT_STATUS} ${API_ERROR}`) {
    dispatch(clearAlerts())
    dispatch(apiError('Fout bij lezen van opdracht status', action.payload))
  }
}

export const queryOpdrachtStatusFlow = ({ dispatch }) => next => action => {
  next(action)

  if (action.type === QUERY_OPDRACHT_STATUS) {
    const url = `${action.payload.url}/${action.payload.query}`

    dispatch(apiRequest({ method: 'GET', url, feature: QUERY_OPDRACHT_STATUS, props: { query: action.payload.query } }))
  }

  if (action.type === `${QUERY_OPDRACHT_STATUS} ${API_SUCCESS}`) {
    if (action.payload === '') {
      dispatch(info(`Geen resultaten voor zoekcriteria "${action.meta.props.query}"`))
      dispatch(selectOpdrachtStatus(''))
    } else {
      dispatch(storeOpdrachtStatus(action.payload))
      if (action.payload.length && action.payload.length === 1)
        dispatch(selectOpdrachtStatus(action.payload[0]._id))
    }
  }

  if (action.type === `${QUERY_OPDRACHT_STATUS} ${API_ERROR}`) {
    dispatch(storeOpdrachtStatus({}))
    dispatch(selectOpdrachtStatus(''))
  }

  if (action.type === `${QUERY_OPDRACHT_STATUS} ${API_ERROR}`) {
    dispatch(clearAlerts())
    dispatch(apiError('Fout bij lezen van opdracht status', action.payload))
  }
}

export const searchOpdrachtStatusFlow = ({ dispatch }) => next => action => {
  next(action)

  if (action.type === SEARCH_OPDRACHT_STATUS) {
    const url = `${action.payload.url}`

    dispatch(apiRequest({ method: 'POST', url, body: action.payload.query, feature: SEARCH_OPDRACHT_STATUS, props: { query: action.payload.query } }))
  }

  if (action.type === `${SEARCH_OPDRACHT_STATUS} ${API_SUCCESS}`) {
    if (action.payload === '') {
      dispatch(info(`Geen resultaten voor zoekcriteria "${action.meta.props.query}"`))
    } else {
      dispatch(storeOpdrachtStatus(action.payload))
    }
  }

  if (action.type === `${SEARCH_OPDRACHT_STATUS} ${API_ERROR}`) {
    dispatch(storeOpdrachtStatus({}))
    dispatch(selectOpdrachtStatus(''))
  }

  if (action.type === `${SEARCH_OPDRACHT_STATUS} ${API_ERROR}`) {
    dispatch(clearAlerts())
    dispatch(apiError('Fout bij lezen van opdracht status', action.payload))
  }
}

export const opdrachtStatusMiddleware = [fetchOpdrachtStatusFlow, queryOpdrachtStatusFlow, searchOpdrachtStatusFlow]
