import {
  STORE_TRACKER_MESSAGES,
} from '../actions/tracker'

import { createSelector } from 'reselect'

const initialState = { items: [ ] }

export default function (state = initialState, action) {
  switch (action.type) {
  case STORE_TRACKER_MESSAGES:
    return { ...state, items: action.payload.items }

  default:
    return state
  }
}



const trackedMessages = (state) => state.tracker.items


/** Get an array of messages. */
export const getTrackedMessages = createSelector(
  trackedMessages,
  trackedMessages => [...trackedMessages]
)
