export const CREATE_TEMPLATE   = '[template] Create'
export const UPDATE_TEMPLATE   = '[template] Update'
export const  FETCH_TEMPLATES  = '[templates] Fetch'
export const  STORE_TEMPLATES  = '[templates] Update store'
export const  STORE_TEMPLATE   = '[template] Update store'

const url = '/rest/testtool/templates'

export const fetchTemplates = () => ({
  type: FETCH_TEMPLATES,
  payload: {
    url
  }
})

export const storeTemplates = items => ({
  type: STORE_TEMPLATES,
  payload: { items },
})


export const createTemplate = template => ({
  type: CREATE_TEMPLATE,
  payload: {
    url,
    template,
  }
})

export const updateTemplate = template => ({
  type: UPDATE_TEMPLATE,
  payload: {
    url: `${url}/${template._id}`,
    template,
  }
})

export const storeTemplate = template => ({
  type: STORE_TEMPLATE,
  payload: { template }
})
