import Status from 'http-status-codes'
import axios from 'axios'

import { API_REQUEST, apiSuccess, apiError } from '../actions/api'
import { getAccessToken } from '../selectors'

const extractErrorsFromAxios = error => {

  if (error.response) {
    if (error.response.status === Status.UNAUTHORIZED || error.response.status === Status.FORBIDDEN) {
      return { message: 'Onvoldoende rechten' }
    }
    const { data } = error.response

    if (data && data.message && data.message.length > 0) {
      return { message: 'Fout reactie ontvangen', messageDetail: data.message }
    }

    if (typeof data === 'string' && data.length > 0) {
      return { message: 'Fout reactie ontvangen', messageDetail: data }
    }

    switch (error.response.status) {
    case Status.NOT_FOUND:
      return { message: 'Niets gevonden' }
    case Status.FORBIDDEN: // intentional fall through
    case Status.UNAUTHORIZED:
      return { message: 'Onvoldoende rechten' }
    default:
      return { message: 'Fout reactie ontvangen', messageDetail: `${error.response.statusText} (${error.response.status})` }
    }
  } else if (error.request) {
    return { message: 'Geen reactie ontvangen.',  messageDetail: error.code }
  } else {
    return { message: 'Fout bij het maken van het verzoek.', messageDetail:error.message }
  }
}

export const api = ({ getState, dispatch }) => next => async action => {
  next(action)

  if (action.type.includes(API_REQUEST)) {
    const { method, url, feature, props } = action.meta

    try {
      const headers = { 'Authorization': `Bearer ${getAccessToken(getState())}` }
      const { data:response } = await axios({ url, method, data:action.payload, headers })

      dispatch(apiSuccess({ response, feature, props }))
    } catch (error) {
      let payload = { error }

      try {
        payload = { error, ...extractErrorsFromAxios(error)  }
      } catch (e) {
        console.error('Ran into an error while trying to figure out what when wrong', e) // eslint-disable-line
      }

      dispatch(apiError({ error:payload, feature }))
    }
  }
}
