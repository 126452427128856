import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  FormControl,
  FormGroup ,
  HelpBlock,
  InputGroup,
} from 'react-bootstrap'

export class SearchForm extends Component {
  state = { query: this.props.query }

  onSubmit = e => {
    e.preventDefault()
    this.props.onSubmit(this.state.query)
  }

  onChangeQuery = event => this.setState({ query: event.target.value })

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        <FormGroup>
          <InputGroup>
            <FormControl value={this.state.query} onChange={this.onChangeQuery} />
            <Button componentClass={InputGroup.Addon} bsStyle="success" onClick={this.onSubmit}>Zoeken</Button>
          </InputGroup>
          <HelpBlock>Vul hier een opdracht ID in en druk op zoeken.</HelpBlock>
        </FormGroup>
      </form>
    )
  }
}

SearchForm.propTypes = {
  query: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
}

SearchForm.defaultProps = {
  query: ''
}

export default SearchForm
