import {
  FETCH_VALUELISTS,
  storeValuelists,
} from '../actions/valuelists'
import {
  API_SUCCESS, API_ERROR,
} from '../actions/api'

import { apiRequest } from '../actions/api'
import { apiError, clearAlerts } from '../actions/alerts'

export const fetchValuelistsFlow = ({ dispatch }) => next => action => {
  next(action)

  if (action.type === FETCH_VALUELISTS) {
    const { url } = action.payload

    dispatch(apiRequest({ method: 'GET', url, feature: FETCH_VALUELISTS }))
  }

  if (action.type === `${FETCH_VALUELISTS} ${API_SUCCESS}`) {
    dispatch(storeValuelists(action.payload))
  }

  if (action.type === `${FETCH_VALUELISTS} ${API_ERROR}`) {
    dispatch(clearAlerts())
    dispatch(apiError('Fout bij lezen van waardelijsten', action.payload))
  }
}

export const valuelistsMiddleware = [fetchValuelistsFlow]
