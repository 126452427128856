import { createSelector } from 'reselect'

import {
  STORE_CLEAR_OPDRACHT_STATUS,
  STORE_OPDRACHT_STATUS,
} from '../actions/opdrachtstatus'

const initialState = {
  items: {},
}

export default function opdrachtStatusReducer(state = initialState, action) {
  switch (action.type) {
  case STORE_OPDRACHT_STATUS:
    let count = 0
    let skip = 0
    let limit = 0
    let payload_items = action.payload.items

    if (payload_items.items) {
      count = payload_items.count
      skip = payload_items.skip
      limit = payload_items.limit
      payload_items = payload_items.items
    }
    if (Array.isArray(payload_items)) {
      const items = payload_items.reduce((acc, o) => {
        acc[o._id] = o

        return acc
      }, {})

      return { ...state, items, stats: { count, skip, limit } }
    } else {

      const { _id } = payload_items
      const items = _id ? { ...state.items, [_id]: payload_items } : state.items

      return { ...state, items, stats: { count, skip, limit } }
    }

  case STORE_CLEAR_OPDRACHT_STATUS:
    return { ...state, items: {} }

  default:
    return state
  }
}

/** get direct reference to opdrachtStatus in store */
const opdrachtStatus = state => state.opdrachtstatus.items
const opdrachtStatusStats = state => state.opdrachtstatus.stats

/** Get an array of opdrachtStatus. */
export const getOpdrachtStatuses = createSelector(
  opdrachtStatus,
  opdrachtStatus => Object.values(opdrachtStatus)
)

export const getOpdrachtStatusStats = createSelector(
  opdrachtStatusStats,
  stats => ({ ...stats })
)

/** Get a opdrachtStatus for the given id. Undefined if unknown.  */
export const getOpdrachtStatusById = (state, id) => opdrachtStatus(state)[id]
