import { createSelector } from 'reselect'
import {
  STORE_RULE,
  STORE_RULES,
  STORE_CLEAR_RULES,
  STORE_REMOVE_RULE,
  UNDO_REMOVE_RULE,
  STORE_OPDRACHTNEMERS
} from '../actions/rules'

const initialState = {
  items: {},
  opdrachtnemers: [],
  removed: []
}

export default function rulesReducer(state = initialState, action) {

  switch (action.type) {
  case STORE_RULES:
    const items = action.payload.items.reduce((acc, r) => {
      acc[r._id] = r

      return acc
    }, {})

    return { ...state, items }

  case STORE_RULE:
    const { rule } = action.payload

    return { ...state, items: { ...state.items, [rule._id]: rule } }

  case STORE_REMOVE_RULE:
    const { [action.payload.id]:removedRule, ...newItems } = state.items

    return { ...state, items:newItems, removed: [...state.removed, removedRule] }

  case UNDO_REMOVE_RULE:
    if (state.removed.length === 0)
      return state

    const removed = state.removed.slice(0)
    const undo = removed.pop()

    return { ...state, items: { ...state.items, [undo._id]: undo }, removed }

  case STORE_OPDRACHTNEMERS:
    const opdrachtnemers = action.payload.items

    return { ...state, opdrachtnemers }

  case STORE_CLEAR_RULES:
    return { ...state, items: {} }

  default:
    return state
  }
}

/** get direct reference to rules in store */
const rules = state => state.rules.items
const opdrachtnemers = state => state.rules.opdrachtnemers

/** Get an array of rules.  */
export const getRules = createSelector(
  rules,
  rules => Object.values(rules).filter(rule => rule.default === undefined)
)

/** Get an array of default rules.  */
export const getDefaultRules = createSelector(
  rules,
  rules => Object.values(rules).filter(rule => rule.default !== undefined)
)

export const getOpdrachtnemers = createSelector(
  opdrachtnemers,
  opdrachtnemers => [...opdrachtnemers]
)
/** Get a rule for the given id. Undefined if unknown.  */
export const getRuleById = (state, id) => rules(state)[id]




