import { combineReducers } from 'redux'

import auth from './auth'
import alerts from './alerts'
import certificates from './certificates'
import valuelists from './valuelists'
import endpoints from './endpoints'
import messages from './messages'
import opdrachtstatus from './opdrachtstatus'
import page from './page'
import templates from './templates'
import tracker from './tracker'
import werkbakken from './werkbakken'
import rules from './rules'
import csv from './csv'
import progress from './progress'

const reducer = combineReducers({
  auth,
  templates,
  messages,
  page,
  alerts,
  valuelists,
  opdrachtstatus,
  endpoints,
  certificates,
  tracker,
  werkbakken,
  rules,
  csv,
  progress,
})

export default reducer
